@import 'src/sass/variables';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-top: 0.2em;
  margin-left: 20px;

  .account {
    max-width: 150px;
    position: relative;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;

    &:after {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 0;
      height: 1px;
      width: 100%;
      transition: transform 0.1s ease-in;
      background-color: $color-black;
      transform: scaleX(0);
    }
  }

  &:hover > div:after {
    transform: scaleX(1);
  }

  .popup {
    position: absolute;
    padding: 5px 0;
    top: 30px;
    right: 0;
    width: 195px;
    box-shadow: 0 4px 16px rgba($color-shadow-dark, 0.05);
    z-index: 1;
    border: 1px solid $color-gallery;
    border-radius: 4px;
    background-color: $color-white;

    & span {
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      height: 42px;
      width: 195px;
      padding: 10px 15px;
      transition: background 0.2s ease;
      background-color: rgba($color-silver-chalice, 0);

      &:hover {
        background-color: rgba($color-silver-chalice, 0.3);
      }
    }
  }
}
.linkPopun {
  display: block;
}