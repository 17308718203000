@import "src/sass/variables";

.edit-password-wrapper {
  position: relative;
}

.edit-password-form {

  .ant-form-item-control-input-content {
    position: relative;
  }
}
