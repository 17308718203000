@import 'src/sass/variables';

.redirectLink {
  display: inline-block;
  padding: 10px;
  font-weight: 600;
  color: $color-mine-shaft;
  border-radius: 4px;
  transition: color 0.2s ease-in;
  font-size: $link-font-bigger-size;

  &:hover {
    color: $color-mine-shaft;
  }
}
