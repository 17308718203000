@import "../../../sass/variables";

.BGC {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.BGC.close {
  display: none;
}

.timeRangePickerComponent {

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: $color-candlelight;
  }


  .ant-picker-panel-container {
    width: 180px;
  }

  .ant-picker-dropdown.ant-picker-dropdown-range .ant-picker-panels {
    width: 180px;
  }

  .ant-picker-panel {
    width: 100%;
  }
  .ant-picker-panel .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-pane {
    width: 100%;
  }

  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    width: 100%;
    padding-top: 4px;
  }

}

.datePickerCouponDropdown {

  .ant-picker-footer-extra {
    width: 280px;
  }
}

.timePicke222r .ant-picker{
  border: 1px solid $color-gallery !important;
}

.timePicke222r.inputError .ant-picker{
  border: 1px solid  $color-guardsman-red !important;
}

.ant-picker.activeInput {
  border: 1px solid $color-candlelight !important;
}

.ant-picker-dropdown {
  width: 400px !important;
  font-weight: 400;
  font-family: $project-font-family, sans-serif;
}

.ant-picker-panel-container {
  padding: 20px 60px;
}

.ant-picker-panel {
  border: none !important;
}

.ant-picker-content {
  width: 280px !important;
}

.ant-picker-body {
  padding: 8px 0 !important;
}
.ant-picker-now {
  display: none !important;
}

.timePickerContainer {
  display: flex;
  column-gap: 10px;
  padding: 8px 0;
}

.ant-picker-today-btn {
  display: none;
}

.timePicker__title{
  padding: 0;
  color: $color-silver-chalice;
  line-height: 130%;
  margin-bottom: 5px;
}

.ant-picker-footer {
  border: none !important;
  width: 100%;
}

.ant-picker-footer-extra {
  padding: 0 !important;
  border: none !important;

  .ant-divider {
    margin: 5px 0 !important;
  }
}

.ant-picker-footer-extra:not(:last-child), .ant-picker-footer {
  border: none;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 0;
}

.ant-picker-datetime-panel {
  flex-direction: column;
}

.btn {
  height: 40px !important;
  background-color: $color-candlelight !important;
  border: none !important;
  outline: none;
  border-radius: 4px;
  font-weight: 500;

  &:hover {
    color: $color-mine-shaft !important;
  }
}

.btn.disabled {
  background-color: $color-gallery !important;
  color: $color-gray !important;

  &:hover {
    border: none !important;
    color: $color-gray !important;
  }
}

.btn.cancel {
  border: none !important;
  background-color: transparent !important;

  &:hover {
    color: $color-mine-shaft
  }
}

.anticon .anticon-close-circle {
  display: none !important;
}

.timePicker {
  width: 135px !important;

  .ant-picker-panel-container {
    padding: 0 !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: $color-candlelight !important;
  }


  .ant-picker-footer{
    display: none;
  }
}