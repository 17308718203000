@import "../../../../../sass/variables";

.container {
  display: flex !important;
  column-gap: 10px !important;
}

.disabled {
  background-color: $color-gallery !important;
}
.cancel {
  background-color: transparent !important;
}
