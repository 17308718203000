@import "../../../../../sass/variables";

.success-body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: $text-modal-content-fontSize;
  font-family: $project-font-family, sans-serif;
  margin-bottom: 20px;

  .total, .info {
    display: grid;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .total {
    grid-template-columns: repeat(2, 1fr);
    background-color: rgba(11, 174, 47, 0.1);
    font-weight: 600;
    color: $color-green;
  }

  .info {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    row-gap: 20px;
    border: 1px solid $color-gallery;
    color: $color-mine-shaft;
  }

  .total__item.right, .info__item.right {
    text-align: right;
  }
}
