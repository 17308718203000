@import "../../../sass/variables";

.ant-picker-range.errorMY .ant-picker-input {
  border: 1px solid $color-guardsman-red !important;
}

.ant-picker-range.errorMY {
  width: 380px !important;
}

.timeRangePicker {
  width: 380px !important;
}

.timeRangePickerMerchant {
  width: 180px;

  .ant-picker-panel-container {
    width: 180px;
    padding: 0;
  }

  .ant-picker-panels {
    width: 100%;
  }

  .ant-picker-content {
    width: 180px !important;
  }

  .ant-picker-ok .ant-btn{
    color: $color-mine-shaft !important;
  }

  .ant-picker-panel-container .ant-picker-panel {
    vertical-align: top;
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
    width: 100%;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: $color-candlelight;
  }

}

.timeRangePicker .ant-picker-input{
  border-radius: 4px;
}
