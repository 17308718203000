@import './src/sass/variables';

.modalBanner {

  .ant-input:hover {
    border-color: $color-candlelight !important;
    border-right-width: 1px !important;
  }

  .ant-form-item-control {
    position: relative !important;
  }

  .ant-modal-content {
    padding-bottom: 20px;
  }
}
.formBanner {

  .ant-select-single .ant-select-selector .ant-select-selection-item{
    line-height: 40px !important;
  }

  .ant-select-selector {
    height: 40px !important;
  }

  .ant-select-selection-search {
    height: 100% !important;
  }

  .ant-form-item-explain-error {
    font-size: $error-font-size !important;
    color: $color-guardsman-red;
  }

  .containerSelectBanner {
    .ant-select.ant-select-single {
      border-radius: 4px !important;
    }
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: $color-guardsman-red !important;
  }

  .ant-form-item-explain-connected {
    position: absolute !important;
    left: 3px !important;
    bottom: -25px !important;
  }

  .ant-input:focus, .ant-input-focused {
    border-color: $color-candlelight!important;
    box-shadow: none !important;
  }
  .ant-select:focus, .ant-select-focused {
    border-color: $color-candlelight!important;
    box-shadow: none !important;
    border-radius: 4px;
  }
}

#formBanner .ant-select-selector {
  height: 40px;
}

#formBanner .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}

#formBanner .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $color-candlelight;
}


.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $color-mine-shaft !important;
  font-weight: 600;
  background-color: $color-candlelight !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $color-wild-sand !important;
}

.uploadImageBanner {

  .uploader {
    min-height: 40px;
    height: 100% !important;
  }

  .ant-upload.ant-upload-select-picture-card {
    height: 100% !important;
  }
}
