@import 'src/sass/variables';

.dropdown-component {
  font-family: $project-font-family, sans-serif;
  position: absolute;
  top: 120px;
  left: 0;
  min-width: 150px;
  padding: 5px 0;
  border-radius: 4px;
  background-color: $color-white;
  -webkit-box-shadow: 2px 2px 10px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 2px 10px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 2px 2px 10px 0 rgba(34, 60, 80, 0.2);
  z-index: 2;

  &__item {
    padding: 10px 15px;
    font-size: $modal-body-fontSize;
    color: $color-mine-shaft;
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: $color-gallery;
      cursor: pointer;
    }

    &[aria-disabled='true'] {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}
