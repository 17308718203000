@import 'src/sass/variables';

.main {
  margin-top: 30px;

  .header {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }

  .input {
    color: $color-gray;
    border-radius: 10px;
    border: 1px solid $color-gallery;
    margin-top: 10px;
  }

  .optionsBlock {

    .head {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        color: $color-gray;
      }

      & > span {
        font-size: 16px;
        line-height: 140%;
        font-weight: 600;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
    }
  }
}