@import 'src/sass/variables';

.main {
  width: 100%;
  min-height: 100%;

  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .image {
        width: 691px;
        height: content-box;

        &.mobile {
          width: 100%;
        }
      }

      .title {
        margin-top: 4%;
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
        color: $color-black;

        &.mobile {
          margin-top: 15%;
        }
      }

      .link {
        text-align: center;
        border-radius: 10px;
        background-color: $color-candlelight;
        margin-top: 2%;
        font-weight: 600;
        padding: 10px 0;
        width: 242px;
        transition: 0.2s background-color ease-in;
        color: $color-black;

        &:hover {
          background-color: $color-candlelight-dark;
        }

        &.mobile {
          margin-top: 10%;
        }
      }
    }
  }
}