@import 'src/sass/_variables.scss';

.container-profile {

  .ant-tabs {
    overflow: visible;
    line-height: 1.4;
  }
}

.tabs .ant-tabs-nav {
  width: 23.5%;
  border: 1px solid $color-gallery;
  border-bottom: none;
  border-radius: 10px;
  overflow: hidden;
  font-family: $project-font-family, sans-serif;
  align-self: flex-start;
}

.tabs .ant-tabs-nav-list .ant-tabs-tab:last-child {
  border-bottom: none !important;
}

.tabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 15px !important;
  border-bottom: 1px solid $color-gallery;
  margin: 0 !important;

  &:last-child {
    border-bottom: none !important;
  }

  .ant-tabs-tab-btn {
    color: $color-mine-shaft !important;
  }
}

.tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: $color-alabaster;
  padding: 15px;
  border: none;
  border-bottom: 1px solid $color-gallery;

  .ant-tabs-tab-btn {
    color: $color-mine-shaft;
    text-shadow: none;
    border: none;
  }
}

.tabs .ant-tabs-content-holder {
  border: none;
}

.tabs .ant-tabs-ink-bar {
  display: none;
}
