@import "src/sass/variables";

.sidebar {
  height: 100vh!important;
  background-color: $color-candlelight!important;

  &.zoomed {
    height: 111vh !important;
  }

  ul {
    border: none!important;
    padding: 0 12px!important;

    li[class="ant-menu-item ant-menu-item-selected"] {
      background: rgba($color-white, 0.2) !important;
    }

    li:global(.ant-menu-submenu.ant-menu-submenu-vertical) {

      div {
        span[class="anticon"] {
          display: none!important;
        }
      }
    }

    li:global(.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open) {

      div:global(.ant-menu-submenu-title) {

        span:global(.anticon) {
          transform: rotate(180deg)!important;
        }
      }
    }

    li:global(.ant-menu-submenu-open) {
      background: rgba($color-white, 0.2) !important;
      border-radius: 4px 4px 0 0 !important;
    }

    *:global(.ant-menu-submenu-title:active), li:global(.ant-menu-submenu-selected) {
      background: rgba($color-white, 0.2) !important;
      border-radius: 4px !important;
    }

    li:global(.ant-menu-submenu) {
      padding-left: 0 !important;

      ul:global(.ant-menu-sub.ant-menu-inline){
        background: rgb($color-white, 0.2) !important;
        border-radius: 0 0 4px 4px !important;
        padding-bottom: 4px !important;

        li {
          height: 35px !important;
          margin-top: 3px !important;
          margin-bottom: 0 !important;

          span {
            font-size: 14px !important;
          }
        }

        li:global(.ant-menu-item-selected) {
         // background: none !important;
        }

        li:global(.ant-menu-item-selected)::before {
          width: 4px !important;
          height: 4px !important;
          background-color: $color-mine-shaft !important;
          content: '' !important;
          position: absolute !important;
          left: 19px !important;
          border-radius: 2px !important;
        }

        li {
          padding-left: 37px !important;
        }
      }
    }

    div:global(.ant-menu-submenu-title) {
      flex-direction: row !important;
      padding: 0 !important;
      margin-bottom: 0 !important;

      span[class="anticon"] {
        position: absolute !important;
        top: 18px !important;
        right: 8px !important;
        transition: .2s !important;
      }

      span[class="anticon ant-menu-item-icon"] {
        min-width: initial !important;
      }
    }

    li {
      user-select: none !important;
      border: none !important;
      height: 40px !important;
      padding: 0 !important;
      padding-left: 11px !important;
      border-radius: 4px;
      margin-bottom: 6px !important;

      span[class="anticon ant-menu-item-icon"] {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 16px !important;
        height: 40px !important;
        margin: 0 !important;
      }

      span[class="ant-menu-title-content"] {
        font-size: 16px !important;
      }

      div[class="ant-menu-submenu-title"] {
        padding-left: 11px !important;
      }
    }
  }

  div {
    display: flex !important;
    flex-direction: column;
  }
}

div:global(.ant-menu-submenu-placement-rightTop) {
  display: none !important;
}

.controls {
  margin-top: auto !important;

  li {
    background: none !important;
  }
}
