@import "src/sass/variables";

.input {
  width: 100%;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  border: 1px solid $color-gallery;
  border-radius: 4px;
  font-size: $input-font-size;
  transition: border-color 0.2s ease-in;
}

.input:focus {
  border-color: $color-candlelight;
  outline: none;
}

.suggestion {
  font-family: $project-font-family;
  position: absolute;
  top: 0;
  transform: translateY(39px);
  background: $color-white;
  border: 1px solid $color-gallery;
  box-shadow: 0 2px 8px 1px rgba(34, 60, 80, 0.2);
  width: 100%;
  z-index: 10;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.listItem {
  padding: 10px 15px;
  cursor: pointer;
}

.listItem:hover {
  background: $color-gallery;
}
