@import "src/sass/variables";

.page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    gap: 10px 0;
    width: 100%;
    padding: 24px 30px;
    background-color: $color-white-dark;
    height: 100%;

    div:global(.ant-table-wrapper) {
        div:global(.ant-spin-nested-loading) {
            height: 100%;

            div:global(.ant-spin-container) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                ul:global(.ant-pagination) {
                    margin: 0;
                }
            }
        }
    }
}

.header {
    display: flex;
    gap: 20px;
}
