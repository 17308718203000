@import "src/sass/variables";

.controls {
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.addButton {
    color: $color-mine-shaft !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-right: 16px !important;

    &:hover {
        color: $color-mine-shaft !important;
    }

    &:focus {
        color: $color-mine-shaft !important;
    }
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
}
