@import 'src/sass/variables';

.info-static {
  display: flex;
  flex-direction: column;

  & > span + span {
    margin-top: 10px;
  }

  .invoice-static {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    &__bold {
      font-weight: 600;
    }
  }
}

.invoice-payment {
  display: grid;
  grid-template: 't s' / 2fr 4fr;
  grid-gap: 10px;
  margin-top: 30px;

  &_item {
    text-align: left;

    &__data {
      text-align: right;
    }
  }
}

.modal-receipt {
  border-radius: 10px;
  overflow: hidden;
  width: 480px !important;

  .ant-modal-header {
    border-bottom: none !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  .ant-modal-close {
    top: 12px;
  }

  .ant-modal-content {
    padding: 20px;

    .ant-modal-title {
      font-size: $modal-title-fontSize !important;
      font-weight: 700 !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
    font-size: $modal-body-fontSize;
  }

  .ant-modal-close-x {
    line-height: 200%;
  }

  .invoice-vouchers {

    .bodyModalReceipt {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      border: 1px solid $color-gallery;
      border-radius: 10px;
      row-gap: 10px;
      padding: 20px 10px;
      margin-top: 30px;

      & + .bodyModalReceipt {
        margin-top: 10px;
      }

      &__data {
        text-align: right;
      }

      &__finally {
        font-weight: 700;
      }
    }

    .invoice-total {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      margin-top: 20px;

      &__subtitle {
        font-weight: 700;
      }

      &__data {
        text-align: right;
      }
    }
  }
}
