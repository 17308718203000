.item {
  margin-top: 15px;
  display: flex;
  align-items: center;

  .title {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}