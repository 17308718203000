@import '../../../sass/variables';

.item {
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 15px;

  & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }

  & + .item {
    margin-top: 10px;
  }

  .title {
    font-weight: 400;
  }

  .info {
    margin-top: 10px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & + div {
        margin-top: 8px;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: $color-gray;
      }
    }
  }
}