@import "src/sass/variables";

.column-employ {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &__image {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    overflow: hidden;

    .crown-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $color-white;
      color: $color-candlelight;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 38px;
      bottom: 2px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}