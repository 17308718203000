@import "../../../../../sass/variables";
.container {
  flex: 0 0 440px;
  padding: 20px;
  border-radius: 4px;
  background-color: $color-white;
  height: calc(100vh - 116px);
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.formItem {
  display: flex !important;
  flex-direction: column !important;
  position: relative;
  margin-bottom: 15px !important;
}
.formItem label {
  width: 100%;

  &:after {
    content: '' !important;
  }
}

.error {
  position: absolute;
  left: 2px;
  bottom: -16px;
  color: $color-guardsman-red;
  font-size: $error-font-size;
}

.labelColumn {
  display: block;
  width: 100%;
  white-space: normal;
  text-align: left;
  font-size: $label-font-size !important;
  color: $color-silver-chalice;
  line-height: 0;
  padding: 0 !important;

  label {
    height: 20px !important;
  }

  &:after {
    content: '' !important;
  }
}

.title {
  color: $color-silver-chalice;
  margin-bottom: 6px;
  font-size: $label-font-size;
}

.checkboxContainer {
  margin-top: 20px;
}

.formItemCheckbox {
  margin-bottom: 0 !important;

  .ant-form-item-row {
    flex-direction: row;
    align-items: center;
  }

  .ant-form-item .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  .labelColumn {
    order: 2;
    width: 94%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;

    label {
      color: $color-black !important;
      cursor: pointer !important;
      margin-top: 2px;
    }
  }

  .ant-form-item-control {
    width: auto;
  }
}

.labelColumn{

  .react-tel-input {
    font-family: $project-font-family, sans-serif !important;
  }

  .react-tel-input:hover .form-control,
  .react-tel-input:hover .flag-dropdown {
    border: 1px solid $color-candlelight !important;
  }
}

.btnModal {
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
}

.cancel {
  background-color: transparent !important;
}

