@import "../../../../sass/variables";

.controls {
  display: flex;
  justify-content: space-between;
}

.addButton {
  color: $color-mine-shaft;
  padding: 10px 20px;
  font-size: $button-font-size;
  font-weight: 500;
  margin-right: 16px;
}

.addButton:hover {
  color: $color-mine-shaft;
}

.addButton:focus {
  color: $color-mine-shaft;
}

.title {
  font-size: $title-font-size;
  font-weight: 700;
  line-height: 140%;
}
