@import '../../../sass/variables';

.quantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  .quantityContainer {
    display: flex;
    align-items: center;
    color: $color-black;

    .count {
      min-width: 20px;
      text-align: center;
      margin: 0 5px;
      width: auto;
      height: 100%;
      font-weight: 400;
      font-size: 16px;

      &.mobile {
        margin: unset;
        padding: 0 10px;
      }
    }

    .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 5px;
      border: 1px solid $color-white;
      transition: border-color 0.2s ease-in;

      &:hover {
        border-color: $color-gallery;
      }

      &:active {
        transition: none;
        border-color: $color-white;
        background-color: $color-gallery;;
      }

      &[aria-disabled="true"] {
        pointer-events: none;
        opacity: 0.2;
      }

      &.mobile {
        width: 36px;
        height: 36px;
        border: 1px solid $color-gallery;
        border-radius: 100%;
      }
    }
  }
}