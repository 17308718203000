@import 'src/sass/variables';

.main {
  padding: 20px 15px;
  display: flex;
  align-items: center;

  .title {
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    margin-left: 15px;
  }
}