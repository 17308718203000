@import 'src/sass/variables';

.wrapper {
  margin: 150px 0;
  padding: 0 17%;
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  &.mobile {
    margin: 50px 0 100px;
    row-gap: 30px;
    padding: unset;
  }

  .preview {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .info {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    &.mobile {
      & span {
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .title {
      margin-bottom: unset;
      font-size: 26px;
      font-weight: 700;
      line-height: 140%;
      color: $color-black;

      &.mobile {
        font-size: 20px;
      }
    }

    .subTitle {
      margin-top: 22px;
      margin-bottom: unset;
      font-size: 22px;
      font-weight: 700;
      line-height: 140%;
      color: $color-black;

      &.mobile {
        font-size: 17px;
      }
    }

    & > span {
      margin-top: 20px;
      font-size: 16px;

      a {
        color: $color-blue;

        &:hover {
          text-decoration: underline;
        }
      }

      i {
        font-size: 14px;
      }
    }
    .replica {
      margin-top: 12px;

      &.mobile {
        margin-top: 8px;
        font-size: 8px;
      }
    }
  }
}