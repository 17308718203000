@import 'src/sass/variables';

.higherSwiper {
  width: 100%;

  .swiper-slide {
    transform: none !important;
    width: 100% !important;
    height: unset !important;

    & img {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &.mobile {
    height: 100%;
    object-fit: cover;

    .swiper-slide {
      height: 100% !important;
      border-radius: 0;
      object-fit: cover;
      transform: unset !important;
      overflow: unset !important;

      & img {
        height: unset;
      }

      & iframe {
        height: 230px !important;
      }
    }

    img {
      border-radius: unset;
    }
  }
}

.swiper-slide-thumb-active {
  opacity: 1 !important;
}

.lowerSwiper {
  margin-top: 20px;
  width: 100%;

  .swiper-slide {
    transform: none !important;
    cursor: pointer;
    width: 220px !important;
    height: 123px !important;
    opacity: 0.3;

    &:hover {
      opacity: 0.7;
    }

    & img {
      border-radius: 10px;
      width: 220px !important;
      height: 123px !important;
    }
  }
}
