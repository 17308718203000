@import 'src/sass/variables';

.main {
  position: absolute;
  display: flex;
  color: $color-black;
  background-color: $color-white;
  padding: 1px 7px 2px;
  opacity: 0.75;
  bottom: 15px;
  left: 15px;
  z-index: 1;
  height: 17px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
}