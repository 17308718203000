@import 'src/sass/variables';

.label {
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  p {
    margin-bottom: 0;
  }
}

.errorInput {
  border: 1px solid $color-guardsman-red;
}