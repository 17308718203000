@import 'src/sass/variables';

.expand, .expanded {
  position: absolute;
  transform: translateX(-25px);
  cursor: pointer;
  color: $color-gray;
}

.expand > svg {
  transition: .2s;
}

.expanded > svg {
  transition: .2s;
  transform: rotate(90deg);
}

td:global(.ant-table-cell) {

  span:global(.ant-dropdown-trigger) {

    svg {
      position: relative;
      top: 3px;
    }
  }
}


