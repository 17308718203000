.containerCheckout {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.mobile {
    position: unset;
    width: 100%;
    padding: 0 15px;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;

    @media screen and (min-width: 460px) {
      max-width: 460px;
      margin: 20px auto;
      zoom: 0.6;
    }
  }
}
