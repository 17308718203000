@import "../../../sass/variables";

.ant-select-item-option-state {
  position: relative;
}

.ant-select-open {
  border: 1px solid $color-candlelight !important;
}

.ant-select-item {
  position: relative;
}

.ant-select-item-option-content {
  order:2;
  margin-left: 20px;
}

.ant-select-item-option-state {
  position: absolute;
  left: 12px;
  top: 5px;
}

.fake_checkbox {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 15px;
  height: 15px;
  border: 1px solid $color-black;
  border-radius: 3px;
  z-index: 20;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border-top: 0;
  border-left: 0;
  border-right: 2px solid $color-white !important;
  border-bottom: 2px solid $color-white !important;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

