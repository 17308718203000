@import 'src/sass/variables';

.profile-notification {

  .profile-merchant-notification {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    cursor: pointer;
    column-gap: 10px;

    .ant-form-item-label {
      order: 2;
      padding-bottom: 0 !important;
      cursor: pointer;

      label {
        cursor: pointer;
        font-size: $label-big-font-size !important;
        color: $color-mine-shaft !important;
        font-weight: 400 !important;
      }
    }
  }
}