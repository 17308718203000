@import 'src/sass/variables';

.icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-black;
  transition: color 0.2s ease-in, border-color 0.2s ease-in;

  & svg {
    height: 25px;
    width: 25px;
  }

  &:hover {
    color: $color-follow-icon !important;;
    border-color: $color-follow-icon;
  }
}

