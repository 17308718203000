.wrapper {
  margin: 150px 0;
  padding: 0 17%;

  &.mobile {
    margin: 0 0 100px;
    padding: unset;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;

    &.mobile {
      font-size: 20px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-top: 70px;

    &.mobile {
      margin-top: 20px;

      & span {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-top: 20px;
      }

      & > div {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
      }
    }

    .second {
      display: block;
      font-size: 16px;
      line-height: 280%;
      font-weight: 400;
      margin-top: 50px;

      &.mobile {
        line-height: 200%;
        font-size: 14px;
        margin-top: 20px;
      }
    }

    & span {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      margin-top: 20px;
    }

    & > div {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }
  }
}