@import '../../../../sass/variables';

.paymentFormContainer {
  width: 514px;
  border: 1px solid $color-mercury;
  border-radius: 10px;
  padding: 25px 30px 30px;
  margin: 0 auto;
}

.paymentButton {
  width: 100% !important;
  font-size: $text-modal-content-fontSize !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  height: 42px !important;
}