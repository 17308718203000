@import '../../../sass/variables';

.addButtonCategories {
  background-color: $color-candlelight;
  outline: none;
  border: none;
  color: $color-mine-shaft;
  padding: 0 20px;
  height: 40px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in, border 0.2s ease-in , color 0.2s ease-in;
  box-shadow: none;
  font-weight: 500;

  &:hover {
    background-color: $color-candlelight-dark;
    color: $color-mine-shaft;
  }

  &:focus {
    background-color: $color-candlelight-dark;
    color: $color-mine-shaft;
  }

  &:active {
    background-color: $color-candlelight-dark;
    color: $color-mine-shaft;
  }
}