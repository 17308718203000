@import 'src/sass/variables';

.cartTable {
  max-width: 100%;

  .ant-table table {
    border-spacing: 0 10px;
    background-color: transparent;
  }

  .ant-table-thead {
    background-color: transparent;
  }

  .ant-table-thead > tr > th {
    white-space: nowrap;
    padding: 0 10px !important;
    border: none;

    &:first-child {
      padding-left: 20px !important;
    }
  }

  .ant-table-cell {
    background: $color-white;
    color: $color-gray;
    font-size: 12px;
    font-weight: 500;

    &:before {
      display: none;
    }
  }

  .ant-table-tbody {

    & > tr > td {
      height: 1px;

      @media screen and (max-width: 1200px) {
        font-size: 14px !important;
      }
    }

    & > tr > td:not(:first-child) {
      padding-left: 10px !important;
    }

    & > tr > td:nth-child(2n) {
      white-space: normal !important;
    }

    & > tr > td:nth-child(2) {
      max-width: 450px !important;
      width: 100%;
    }

    .ant-table-cell {
      font-size: 16px;
      padding: 20px;
    }

    & td {
      vertical-align: top;
      border: none !important;
    }

    &:hover {

      & td {
        background: transparent !important;
      }
    }
  }
}

.cartTable .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.cartTable .ant-table-tbody tr {
  background: transparent !important;
  outline: 1px solid $color-gallery !important;
  border-radius: 10px !important;
  transition: box-shadow 0.2s ease-in;

  &.with-promo {
    outline: 1px solid $color-green !important;

    &:hover {
      box-shadow: 0 4px 16px rgba($color-green, 0.2);
    }
  }

  &:hover {
    box-shadow: 0 4px 16px rgba($color-shadow-dark, 0.05);
  }

  td {
    background: transparent !important;
  }
}

@media screen and (max-width: 1200px) {
  .ant-table-container tr *:is(td, th):first-child {
    padding-left: 20px !important;
  }

  .cartTable .ant-table-tbody .ant-table-cell {
    padding: 20px 8px;
  }
}

