@import 'src/sass/variables';

.wrapper {
  margin: 150px 0;
  padding: 0 17%;

  &.mobile {
    margin: 50px 0 100px;
    padding: unset;

    span {
      font-size: 14px;
      margin-top: 20px;
    }

    & > div {
      margin-top: 30px;
    }
  }

  h1, h2 {
    margin-bottom: unset;
    line-height: 140%;
    color: $color-black;
  }

  span {
    line-height: 140%;
    color: $color-black;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;

    &.mobile {
      font-size: 20px;
    }
  }

  & > div {
    margin-top: 70px;
    display: flex;
    flex-direction: column;

    .subtitle {
      font-size: 20px;

      &.mobile {
        font-size: 16px;
      }
    }
  }
}