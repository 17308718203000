@import '../../../sass/variables';

.account-btns {
  display: flex;
  margin-top: 50px;
  column-gap: 10px;
}

.active {
  background-color: $color-candlelight !important;
  transition: background-color 0.2s ease-in, border 0.2s ease-in;

  &:focus {
    background-color: $color-candlelight !important;
    border: 1px solid $color-candlelight !important;
    color: $color-mine-shaft !important;
  }

  &:hover, &:active {
    color: $color-mine-shaft !important;
  }
}

.cancel {
  border: 1px solid $color-mine-shaft !important;
  background-color: transparent !important;

  &:hover, &:active, &:focus {
    background-color: transparent !important;
    color: $color-mine-shaft !important;
    border: 1px solid $color-mine-shaft !important;
  }
}
