@import 'src/sass/variables';

.item {
  position: relative;
  border: solid $color-gallery;
  border-width: 1px 0 0 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;

  &.active {
    background-color: $color-category;
  }

  &.pointer {
    cursor: pointer;
  }

  .category {
    padding: 0 13px 0 21px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 42px;

    &:hover {
      background-color: $color-category;
    }

    .container {
      display: flex;
      align-items: center;

      & img:hover {
        text-decoration: none;
      }

      .title {
        position: relative;
        margin-left: 12px;
        margin-top: 3px;
        font-size: 16px;
        font-weight: 400;
        color: $color-black;
        line-height: 140%;
      }
    }
  }
}

.arrow {
  position: relative;
  padding: 15px 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: border-color 0.2s ease-in;

  &.active {
    transform: rotate(180deg);
  }
}