html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, center, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

q {
  &:before, &:after {
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

*:not([class*="gmail"]) {
  outline: none;
  box-sizing: border-box;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  width: auto !important;
}

button {
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
}

strong {
  letter-spacing: 1px;
}

input[type='file'] {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 0;
  left: 0;
  top: 0;
  position: absolute;

  &:disabled {
    cursor: not-allowed;
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}

input, textarea {
  -webkit-text-fill-color: inherit;
  appearance: none;
  -webkit-appearance: none;

  &:disabled {
    opacity: 1 !important;
  }

  &:placeholder-shown {
    width: 100%;
  }
}