@import '../../../sass/variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.wrapper .ant-modal-content {
  padding: 20px !important;
  font-family: $project-font-family, sans-serif !important;
}

.wrapper .ant-modal-header {
  padding: 0 !important;
}

.wrapper .ant-modal-body {
  padding: 0 !important;
}

.wrapper .ant-modal-title {
  margin-bottom: 20px;
}

.body {
  display: flex;
  column-gap: 18px;
  margin-bottom: 20px;
}

.text p {
  margin-bottom: 5px;
  font-size: $upload-image-font-size;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.titleDocument {
  font-weight: 500;
}
