@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import '../../../sass/variables';

.containerCookie {
  position: fixed;
  left: 50%;
  bottom: 20px !important;
  transform: translateX(-50%);
  background: $color-white;
  color: $color-mine-shaft;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 30px 25px 30px 70px;
  column-gap: 20px;
  width: 65%;
  z-index: 999;
  font-family: $project-font-family, sans-serif;
  box-shadow: 0 4px 16px rgba(20, 20, 20, 0.05);
}

.cookie-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.btns-cookie {
  flex: 0 0 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  height: 73px;
}

.cookie-text {
  font-size: 14px;
  margin-bottom: 0;
}

.cookie-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}

#rcc-decline-button, #rcc-confirm-button {
  align-self: flex-end;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-mine-shaft;
  font-weight: 600;
  border-radius: 10px;
}

.cookie-policy {
  text-decoration: underline;
}

#rcc-confirm-button {
  background-color: $color-candlelight;
  border: 1px solid $color-candlelight;
  transition: background-color 0.2s ease-in, border 0.2s ease-in;

  &:hover {
    background-color: $color-candlelight-dark;
    border: 1px solid $color-candlelight-dark;
  }
}

#rcc-decline-button {
  border: 1px solid $color-mine-shaft;
}
