.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .content {
    display: flex;
    position: relative;
    margin-top: 80px;
    flex: 1 0 auto;

    &.mobile {
      margin-top: unset;
      margin-bottom: 65px;
    }
  }
}