@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import 'src/sass/variables';

body {
  width: 100% !important;
}

.zoom-app {
  zoom: 0.9;
}

.scanner-mode {
  background-color: $color-black;
}

.zoom-tablet {
  zoom: 0.7;
}

html {
  overflow-y: scroll;
}

input,
input[type="password"],
input[type="text"]{
  -webkit-box-shadow: inset 0 0 0 50px $color-white;
  -webkit-text-fill-color: $color-black;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}