.main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  opacity: 0;
  pointer-events: none;
  padding: 0;

  &.opened {
    height: 100%;
    opacity: 1;
    pointer-events: all;
    padding: 5px 12px;
  }
}