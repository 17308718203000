@import "../../../../sass/variables";

.containerCheckoutFinish {
  width: 460px;

  &.mobile {
    max-width: 100%;
    width: 100%;
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    margin: unset;
  }
}
