@import "src/sass/variables";

.tabs {
  height: 50%;

  :global(.ant-tabs-nav) {

    :global(.ant-tabs-nav-wrap) {

      :global(.ant-tabs-nav-list) {
        padding-right: 110px;

        :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
          display: none;
        }

        :global(.ant-tabs-tab.ant-tabs-tab-active) {

          :global(.ant-tabs-tab-btn) {
            color: $color-mine-shaft;
          }
        }

        :global(.ant-tabs-tab):not(:global(.ant-tabs-tab-active)) {
          color: $color-silver-chalice;
        }

        :global(.ant-tabs-tab):not(:first-child) {
          margin-top: 20px;
        }

        :global(.ant-tabs-tab) {
          padding: 0;
        }
      }
    }
  }
}