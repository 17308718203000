@import 'src/sass/variables';

.main {
  width: 100%;

  & span,
  a {
    line-height: 140%;
    font-weight: 400;
    color: $color-black;
  }

  .name {
    font-weight: 700;
    font-size: 20px;
  }

  .links {
    display: flex;
    flex-direction: column;

    .link {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}