@import 'src/sass/variables';

.logoContainer {
  display: flex;
  flex-direction: row;

  &.withOnClick {
    cursor: pointer;
  }

  .logo img {
    width: 60px;
    height: 60px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      line-height: 140%;
      font-weight: 700;
      font-size: 30px;
      margin-left: 5px;
      color: $color-mine-shaft;
    }

    .subtitle {
      color: $color-silver-chalice;
      line-height: 14px;
      font-size: 10px;
      font-weight: 400;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}