@import '../../../../../../sass/variables';
.btn {
  width: 100%;
  height: 40px !important;
  background-color: $color-white-dark !important;
  color: $color-mine-shaft;
  border: none;
  font-weight: 500;

  &:hover {
    background-color: $color-white-dark !important;
    border: none !important;
    color: $color-mine-shaft;
  }
}

.error {
  border: 1px dashed $color-guardsman-red;
}

.errorText {
  margin-top: 5px;
  font-size: $error-font-size;
  color: $color-guardsman-red;
}
