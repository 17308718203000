@import "src/sass/variables";
.expand, .expanded {
  position: absolute;
  transform: translateX(-25px) rotate(-90deg);
  cursor: pointer;
  color: $color-silver-chalice !important
}

.expand > svg {
  transition: .2s;
}

.expanded > svg {
  transition: .2s;
  transform: rotate(90deg);
}
