@import 'src/sass/_variables.scss';

.coupons-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.account-title {
  font-weight: 700;
  font-size: $edit-info-title-fontSize;
  color: $color-mine-shaft;
}

.searchInputAddClass {
  border-radius: 10px !important;
  border-color: $color-gallery !important;
}