@import "src/sass/variables";

.tableOrders {

  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-empty {

    .ant-table-placeholder {
      height: 100%;
    }
  }

  .ant-table-tbody {

    tr {

      .ant-table-cell {
        font-size: $table-cell-font-size;
        background: $color-white;
        padding: 0 16px 0 0 ;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
