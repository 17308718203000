@import 'src/sass/variables';


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgb($color-white,0.5) !important;
}

.turnOfBgInSidebar {

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: $color-candlelight !important;
  }
}

.ant-menu {
  color: $color-mine-shaft;
}

.ant-menu-item-selected {
  color: $color-mine-shaft;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.arrowBtnSidebar {
  background: none !important;
  border: none !important;
}
