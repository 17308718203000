@import 'src/sass/variables';

.merchant-profile {
  font-family: $project-font-family, sans-serif;

  &__title {
    color: $color-mine-shaft;
    font-size: $edit-info-title-fontSize;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.merchant__info {
  display: flex;
  column-gap: 20px;
}
