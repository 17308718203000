@import '../../../sass/variables';

.main {

  .title {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
  }

  .button {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    padding: 10px 20px;
    transition: background 0.3s ease-in;
    border-radius: 10px;
    background-color: $color-candlelight;
    margin-top: 0.2em;
    white-space: nowrap;

    &:hover {
      background-color: $color-candlelight-dark;
    }
  }

  .submitButton {
    width: 100%;
    border-radius: 10px !important;
    height: 42px !important;
    color: $color-black !important;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    background-color: $color-candlelight;
    transition: border-color 0.2s ease-in, background-color 0.2s ease-in;
    opacity: 1;

    &:hover {
      border-color: $color-candlelight-dark;
      background-color: $color-candlelight-dark;
    }
  }

  .util {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    user-select: none;
    color: $color-silver-chalice;
  }

  & label::before {
    display: none !important;
  }

  .input {
    border-radius: 10px;
  }

  .terms {
    display: inline-block;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: $color-silver-chalice;

    .rules {
      cursor: pointer;
      display: inline-block;
      position: relative;
      color: $color-black;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transition: background 0.1s ease;
        background-color: rgba($color-black, 1);
      }

      &:hover::after {
        background-color: rgba($color-black, 0);
      }
    }
  }

  input {
    -webkit-box-shadow: inset 0 0 0 50px $color-white;
    -webkit-text-fill-color: $color-black;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  .or {
    user-select: none;
    font-size: 16px;
    display: inline-block;
    color: $color-gray;
    width: 100%;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    margin-top: 30px;
  }

  .signIn {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 42px;
    color: $color-black;
    height: 42px;
    border: 1px solid $color-black;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 10px;
    transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

    &:active {
      background-color: $color-candlelight-dark;
      border-color: $color-candlelight-dark;
    }
  }
}