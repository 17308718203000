@import "../../../../sass/variables";

.row {
  position: relative;
  margin-bottom: 7px;
  width: 380px !important;

  .ant-picker {
    display: flex;
    column-gap: 10px;
    background: none;
    padding: 0;
    height: 32px;
    border: none;
  }

  .ant-picker-range-separator {
    display: none;
  }

  .ant-picker-input {
    background-color: $color-white;
    width: 100%;
    padding: 3px 10px;
    border: 1px solid $color-gallery;
    height: 40px;
    border-radius: 4px;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker {
    height: 40px !important;
  }
}

.deleteBacketInterval {
  position: absolute;
  right: -20px;
  top: 21px;
  transform: translateY(-50%);
  cursor: pointer;
}

