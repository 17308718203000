@import 'src/sass/variables';
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .user {
    padding: 30px 0 20px;
    width: 100%;
    border: 1px solid $color-gallery;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 27%;
    }

    h1,
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $color-black;
      margin-top: 30px;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      margin-top: 10px;
    }
  }
}