@import '../../../../../sass/variables';

.success-btns {
  display: flex;
  column-gap: 10px;

  &__btn {
    flex: 0 1 50% !important;
    border-radius: 10px !important;
    font-size: $text-modal-content-fontSize !important;
    font-weight: 600 !important;
    height: 42px !important;
  }

  &__btn.cancel {
    background-color: transparent !important;
    border: 1px solid $color-mine-shaft !important;
  }
}
