@import 'src/sass/variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translateY(60%);
  border-radius: 100px;
  background-color: rgba($color-white, 1);
  cursor: pointer;
  transition: background 0.2s;
  z-index: 1;
  box-shadow: 0 4px 16px rgba($color-shadow-dark, 0.3);

  &[aria-disabled="true"] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:active {
    transition: none;
    background-color: rgba($color-white, 1);
  }

  &.hidden {
    display: none;
  }

  & > svg {
    transform: scale(1.5);
  }
}

.next {
  margin-left: 92%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 1921px) {
    margin-left: calc(50% + $slider-max-image-width / 2);
  }
}

.prev {
  margin-left: 8%;
  transform: translate(-50%, -50%);


  @media screen and (min-width: 1921px) {
    margin-left: calc(50% - $slider-max-image-width / 2);
  }
}