@import 'reset';
@import 'variables';
@import 'core';
@import '~antd/dist/antd.css';
@import 'fonts';
@import 'tables';
@import 'modal';

body {
  font-family: 'Inter', sans-serif !important;
  line-height: 1.4 !important;
}

.ant-btn-primary {
  text-shadow: none !important;
}

.ant-upload-drag {
  border: 1px dashed $color-silver-chalice !important;
  background-color: $color-gallery !important;
}

.ant-btn {
  span {
    font-weight: 500;
  }

  border-radius: 4px !important;
  box-shadow: none !important;
}

.ant-dropdown {
  width: 150px;

  .ant-dropdown-menu {
    border-radius: 4px;
    padding: 5px 0;
  }
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  span {
    font-size: 14px;
  }

  padding: 10px 15px;
}

.upload_subtitle {
  font-weight: 400;
}

input.ant-input {
  padding: 10px 15px;
  border-radius: 4px;
}

.uploadImageContainer {
  width: 100%;

  .ant-upload.ant-upload-select-picture-card {
    border-radius: 4px;
  }
}

.anticon.anticon-eye {
  display: unset !important;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  left: 64px !important;

  .ant-tooltip-content {
    .ant-tooltip-inner {
      a {
        color: $color-mine-shaft;
      }

      border-radius: 4px !important;
    }
  }
}

.ant-click-animating-node {
  box-shadow: none !important;
}

.ant-switch-small .ant-switch-handle {
  width: 10px !important;
  height: 10px !important;
}

button[class="ant-switch ant-switch-small ant-switch-checked"] {
  background-color: $color-mine-shaft;
}

button[class="ant-switch ant-switch-small"] {
  background-color: $color-silver-chalice;

  .ant-switch-handle {
    left: 3px;
  }
}

.ant-switch:focus {
  box-shadow: none !important;
}

.ant-switch-handle {
  top: 3px !important;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &:hover {
    color: $color-mine-shaft !important;
    background-color: $color-candlelight-dark !important;
  }

  &:focus {
    color: $color-mine-shaft !important;
    background-color: $color-candlelight !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $color-gorse !important;
}

.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.ant-space-item, .ant-modal-footer {
  .addButton:hover {
    color: $color-mine-shaft !important;
    background-color: $color-candlelight-dark !important;
  }

  .addButton:focus {
    color: $color-mine-shaft !important;
    background-color: $color-candlelight !important;
  }
}

.ant-dropdown-menu-title-content {
  .ant-checkbox-wrapper {
    width: 16px !important;
  }
}

.ant-table-filter-dropdown-btns {
  justify-content: normal !important;
  gap: 8px !important;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.ant-select-selection-search-input {
  -webkit-box-shadow: none !important;
}

input {
  -webkit-text-fill-color: inherit !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-mine-shaft !important;
}

.ant-radio-inner::after {
  background-color: $color-mine-shaft !important;
}

.ant-table {
  height: 100% !important;
  background: transparent !important;
}

.ant-picker {
  height: 40px;
  border: 1px solid $color-gallery;
  border-radius: 4px !important;

  .ant-picker-input {
    height: 32px;
  }

  .ant-picker-input input {
    height: 100%;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: $color-mine-shaft !important;
  background: $color-candlelight;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-picker-range .ant-picker-active-bar {
  display: none !important;
}
