@import 'src/sass/variables';

.wrapperRefund {
  padding: 20px 0;
}

.modal-refund {
  font-family: $project-font-family, sans-serif;
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-header {
    border-bottom: none !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  .ant-modal-close {
    top: 12px;
  }

  .ant-modal-content {
    padding: 30px !important;

    .ant-modal-title {
      font-size: $modal-title-fontSize !important;
      font-weight: 700 !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .comment__label {
    min-height: 260px;
    position: relative !important;

    .ant-col.ant-form-item-label {
      padding: 0;
      margin-bottom: 4px;

      .ant-form-item-required {
        font-weight: 500;
        font-size: $modal-small-fontSize;
        color: $color-gray;
      }
    }
  }

  .refund__button {
    color: $color-mine-shaft !important;
    font-size: $button-font-bigger-size !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 42px !important;
    background-color: $color-candlelight !important;
    transition: background-color 0.2s ease-in, border  0.2s ease-in !important;
    border:none !important;

    &:hover {
      background-color: $color-candlelight-dark !important;
      border:none !important;
    }
  }

  &__text {
    font-weight: 400;
    font-size: $text-font-size;
    color: $color-mine-shaft;
    margin-bottom: 20px;
  }


  #refund_comment {
    min-height: 260px;
    border-radius: 10px;
    border: 1px solid $color-gallery;
    font-size: $text-font-size;
    padding: 10px 15px;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    background-color: $color-white;
    border-color: $color-guardsman-red !important;
  }

  .ant-form-item-explain-error {
    color: $color-guardsman-red;
    font-size: $error-font-size;
  }
}

.errorText {
  position: absolute;
  color: $color-guardsman-red;
  font-size: $error-font-size;
}