@import 'src/sass/variables';

.swiper-container {
  width: 100% !important;

  &.mobile {
    margin-top: 50px;
    margin-bottom: 35px;
    overflow: unset;
  }
}

.swiper-container:hover {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
}

.swiper-slide {
  width: 84% !important;
  max-height: 900px !important;
  transform: scale(0.8) !important;
  transition: scale 0.4s ease-in;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (min-width: 1921px) {
    width: 1600px !important;
  }

  & img {
    object-fit: cover;
  }

  &.mobile {
    height: 100%;
    max-height: 185px !important;
    transform: unset !important;
    width: 100% !important;
    object-fit: cover;

    @media screen and (min-width: 460px) {
      height: calc(100vh - 110px) !important;
      max-height: unset !important;
    }
  }
}

.slide-img {
  aspect-ratio: 8 / 3;
  position: relative;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;

  @media screen and (min-width: 1921px) {
    max-width: 1600px;
  }
}

.slide-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide-active {
  transform: scale(1) !important;
}

.swiper-slide-active:hover + .swiper-button-prev {
  display: flex;
}

/*!*moving arrows - icons font*!*/
.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
  display: none;
  position: absolute;
  top: 50%;
  width: 60px;
  height: 60px;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 50%;
  background-color: $color-white;
}

.swiper-container .swiper-button-prev {
  left: 249px;
  display: none !important;
}

.swiper-container .swiper-button-next {
  right: 249px;
  display: none !important;
}

.swiper-container .swiper-button-prev::after,
.swiper-container .swiper-button-next::after {
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.bullets {
  display: flex;
  position: absolute;
  bottom: -50px;
  padding: 15px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.swiper-pagination .swiper-pagination-bullet {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid $color-gallery;

  &.swiper-pagination-bullet-active {
    border-color: $color-black;
  }

  & + .swiper-pagination-bullet {
    margin-left: 20px;
  }
}

.main {
  padding: 0 15px;
  overflow: hidden;
}
