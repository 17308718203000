@import 'src/sass/variables';

.main {
  width: 100%;
  border: 1px solid $color-gallery;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;

  &.mobile {
    height: unset;
    border: none;
    padding: unset;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;

      & svg {
        color: $color-white;
      }

      & span {
        width: 100%;
        margin-left: 9px;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }

    & > span {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      color: $color-silver-chalice;
      transition: color 0.2s ease-in;

      &:hover {
        color: $color-black;
      }

      &:active {
        transition: none;
        color: $color-silver-chalice;
      }
    }
  }

  .form {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;

    .inputs {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 20px !important;

      .empty {
        width: 20px;
      }

      .label {
        position: absolute;
        height: 17px;
        top: -21px;
        font-size: 12px;
        line-height: 140%;
        color: $color-black;
        font-weight: 500;
      }

      .input {
        width: 100%;
        height: 42px;
        border-radius: 10px;
        font-size: 16px;
      }
    }
  }
}

.titleInput {
  width: 100%;
  height: 42px;
  border-radius: 10px !important;
  font-size: 16px;
}

.item {
  width: 100%;
  margin-bottom: 0;
  position: relative;

  .searchIcon {
    cursor: pointer;
    height: 16px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-gray;
  }
}

.itemSort {
  width: 100%;
}

:global {
  .ant-form-item-control-input-content {
    display: block !important;
  }
}

.top {
  margin-top: -10px;
}

.mobileFilters {
  background-color: $color-white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  padding: 20px 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: 0;
  pointer-events: none;

  &.active {
    z-index: 3;
    opacity: 1;
    pointer-events: all;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 460px) {
      width: 65%;
    }

    .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 30px;

      & > span {
        font-weight: 500;
        font-size: 14px;
        margin: 0 auto;
        padding-right: 7.5px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: 460px) {
      width: 65%;
    }

    & > button {
      height: 40px;
      width: 47%;
      border-radius: 10px;
    }

    .reset {
      border: 1px solid $color-black;
    }

    .allow {
      border: 1px solid $color-candlelight;
      background-color: $color-candlelight;
    }
  }
}

.radio {

  label + label {
    margin-top: 12px;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1.3px solid $color-black;
  }

  .ant-checkbox-wrapper.ant-checkbox-group-item {

    :last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      color: $color-black;
    }
  }
}
