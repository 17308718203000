@import "src/sass/variables";

.formItem {
  position: relative;
}
.input {
  &:hover {
    border: 1px solid $color-gallery !important;
  }
}

.inputError {
  border: 1px solid  red !important;
}
.errorMessage {
  position: absolute;
  bottom: -18.5px;
  left: 1px;
  font-size: 12px;
  color: $color-red;
}
