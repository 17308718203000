@import 'src/sass/variables';

.social {
  display: flex;
  margin-top: 10px;
  align-items: center;

  .icon {
    display: flex;
    cursor: pointer;
    transition: color .2s ease-in;
    color: $color-mine-shaft;
    width: 24px;
    height: 24px;

    & + .icon {
      margin-left: 15px;
    }

  }
}