@import 'src/sass/variables';

.wrapper .ant-modal-content {
  padding: 20px !important;
  font-family: $project-font-family, sans-serif !important;
}

.wrapper .ant-modal-header {
  padding: 0 !important;
}

.wrapper .ant-modal-body {
  padding: 0 !important;
}

.wrapper .ant-modal-title {
  margin-bottom: 20px;
}

.body {
  display: flex;
  column-gap: 18px;
  margin-bottom: 20px;
}

.text p {
  margin-bottom: 5px;
  font-size: $upload-image-font-size;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.btn {
  font-weight: 500;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: $color-candlelight !important;
  outline: none !important;
  border: none !important;
  color: $color-mine-shaft !important;
  padding: 0 20px;
  height: 40px !important;
  border-radius: 3px !important;
  transition: background-color 0.2s ease-in, border 0.2s ease-in , color 0.2s ease-in !important;
  box-shadow: none !important;

  &:hover {
    background-color: $color-candlelight-dark !important;
  }
}
