@import 'src/sass/_variables.scss';

.button {
  padding: 10px 20px !important;
  font-weight: 600;
  font-size: $button-font-size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important;
  color: $color-mine-shaft;
  height: auto;

  &:active {
    color: $color-mine-shaft;
  }
}