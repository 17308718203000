@import '../../../../sass/variables';
.header {

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__title {
    font-size: $title-coupon-font-size;
    font-weight: 700;
    margin-bottom: 0;
  }
}