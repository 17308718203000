@import "../../../../sass/variables";

.uploadImageContainer {
  position: relative;

  .ant-upload.ant-upload-select-picture-card {
    height: 100px;
  }
}

.deleteImage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: $color-white;
}

.uploader {
  position: relative !important;
  height: 100px !important;
  
  .ant-upload {
    width: 100% !important;
  }
}

.image {
  top: 0;
  left: 0;
  width: 60px !important;
}

.hasImage {
  width: 100px !important;

  .ant-upload.ant-upload-select-picture-card {
    border: none;
  }
}

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  text-align: center;
}
.ant-upload-list-picture-card-container {
  position: relative;
}
.uploadImage {

  .anticon-delete {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px !important;
    height: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}