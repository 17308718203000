@import 'src/sass/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.account-title {
  font-weight: 700;
  font-size: $edit-info-title-fontSize;
  margin-bottom: 40px;
  color: $color-mine-shaft;
}

.account-form {
  width: 40%;
  min-width: 400px;
  font-family: $project-font-family, sans-serif;
}

.account-form.show-form {

  .ant-input[disabled] {
    background-color: transparent;
    border-color: transparent;
    cursor: default;
  }
}

.account_label-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.account-label-class {
  position: relative;
  height: 40px;
  margin-bottom: 17px;
}

.account-label-class.show-label {
  margin-bottom: 10px !important;
}

.account-label-class label{
  color: $color-gray;
  font-size: $label-font-size;

  &:after {
    content: '';
  }
}

.input-phone-editInfo {
  height: 42px !important;
}

.input-phone-editInfo {
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.account_wrapper-col {

  .ant-form-item-control-input {
    height: 100%;

    .ant-input-affix-wrapper {
      border-radius: 10px;
    }

    .ant-form-item-control-input-content .react-tel-input:hover .form-control, .ant-form-item-control-input-content .react-tel-input:hover .flag-dropdown {
      border: 1px solid $color-gallery;
    }

    .account-label-class__tel-input {
      padding-left: 15px;
    }

    .react-tel-input .form-control {
      border-radius: 10px;
    }
    .react-tel-input .flag-dropdown {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0;
      background-color: $color-wild-sand;
      border-radius: 10px 0 0 10px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      padding: 0 0 0 8px;
      border: none !important;
      border-radius: 10px 0 0 10px !important;
    }

    .ant-form-item-control-input-content,
    .ant-input-password{
      height: 100%;

      .ant-input {
        height: 100%;
        border-radius: 10px;
        font-size: 16px;
        color:$color-mine-shaft;
      }
    }
  }
}

.account-label-class__tel-input {

  .account_wrapper-col .ant-form-item-control-input .ant-form-item-control-input-content .react-tel-input:hover .form-control, .account_wrapper-col .ant-form-item-control-input .ant-form-item-control-input-content .react-tel-input:hover .flag-dropdown {
    border: none !important;
  }
}

.btnLink {
  padding: 10px 20px !important;
  font-weight: 600;
  font-size: $link-font-bigger-size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: $color-mine-shaft;
  height: auto;

  &:active {
    color: $color-mine-shaft;
  }
}

.errorInput {
  border-color: $color-guardsman-red;
}

.errorTextAccount {
  position: absolute;
  bottom: -17px;
  left: 3px;
  font-size: $error-font-size;
  color:$color-guardsman-red;
}

.errorTextAccount.bigError {
  width: 360px;
  bottom: 1px;
  left: 345px;
}

.account-phone__empty {
  padding: 10px 15px;
}
