@import '../../../sass/variables';
.search {
  flex: 0 0 402px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $color-gallery;
  transition: border 0.2s ease-in;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    border: 1px solid $color-candlelight !important;
  }
}
