.main {

  .wrapper {
    margin: 150px 0;
    padding: 0 17%;

    &.mobile {
      padding: unset;
      margin: 50px 0 100px;

      & span {
        font-size: 14px;
      }

      & > div {
        margin-top: 50px;

        :nth-child(1) {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }

    & span {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      margin-top: 20px;
    }

    & > div {
      margin-top: 70px;
      display: flex;
      flex-direction: column;

      :nth-child(1) {
        font-weight: 700;
        font-size: 26px;
      }
    }
  }
}