@import 'src/sass/variables';

.wrappMap {
  margin: 0 -15px;
}

.locationWrapperDesk {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 30px;
}

.locationWrapperMob {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-radius: 10px;
}

.locationWrapperDesk {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 30px;
}

.titleDesc {
  font-weight: 700;
  font-size: $title-font-size;
  line-height: 140%;
}
.titleMob {
  font-weight: 700;
  font-size: $title-font-size;
  line-height: 140%;
  margin-bottom: 20px;
}

