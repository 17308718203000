@import "../../../../../sass/variables";

.success-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  font-family: $project-font-family, sans-serif;
  margin-bottom: 30px;

  &__text {
    text-align: center;

    h6{
      font-size: $text-modal-title-fontSize;
      font-weight: 700;
      margin-bottom: 5px;
    }
    p {
      font-size: $text-modal-content-fontSize;
      font-weight: 400;
      margin: 0;
    }
  }
}
