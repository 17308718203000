@import '../../../sass/variables';

.main {
  width: 100%;

  .title {
    line-height: 140%;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  & label::before {
    display: none !important;
  }

  .input {
    border-radius: 10px;
    padding: 10px 15px;
    transition: none;
  }

  .form {
    margin-top: 30px;
  }

  .submitButton {
    width: 100%;
    border-radius: 10px !important;
    height: 42px !important;
    color: $color-black !important;
    padding: 10px 20px;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 140%;
    background-color: $color-candlelight;
    transition: border-color 0.2s ease-in, background-color 0.2s ease-in;
    opacity: 1;

    &:active {
      border-color: $color-candlelight-dark;
      background-color: $color-candlelight-dark;
    }
  }

  .util {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    user-select: none;
    color: $color-silver-chalice;
  }

  .memberBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;

    .label {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: $color-silver-chalice;
    }

    .signUp {
      color: $color-black;
      display: inline-block;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .passwordContainer {
    position: relative;

    .forgot {
      display: inline-block;
      position: absolute;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500 !important;
      right: 0;
      color: rgba($color-black, 1);
      top: -22px;
      transition: 0.2s ease;
      z-index: 1;

      &:hover {
        color: rgba($color-black, 0.7);
      }
    }
  }

  input {
    -webkit-box-shadow: inset 0 0 0 50px $color-white;
    -webkit-text-fill-color: $color-black;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  .checkbox {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin-left: 2px;
    padding-top: 0.2em;
  }
}