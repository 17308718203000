.profile-container {
  position: relative;
}

.profile-photo {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.closeDropdown {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
