@import "../../../../sass/variables";

.container {
  width: 640px !important;
  margin: 0 auto;

  .ant-form-horizontal .ant-form-item-label {
    flex-grow: 1 !important;
  }

  .ant-input {
    border: 1px solid $color-gallery;
  }

  .ant-form-horizontal {
    width: 100% !important;
    height: 100% !important;
  }

  .ant-form-item  {
    justify-content: space-between !important;
    margin-bottom: 20px !important;
    align-items: center;

  }

  .ant-form-item-control {
    flex: 0 0 400px !important;
  }
}

.ant-divider-horizontal {
  width: 550px;
  margin: 0 auto 24px;
}

label {
  display: block;
  width: 100%;
  white-space: normal;
  text-align: left;
  font-size: $label-font-size !important;
  color: $color-gray !important;
  font-weight: 500;

  &:after {
    content: '' !important;
  }
}

