.voucher-table {
  height: 100%;

  .ant-table-container tr *:is(td, th):first-child {
    padding-left: 48px !important;
  }

  .ant-btn-icon-only.ant-dropdown-trigger {
    background: transparent !important;
    box-shadow: none;
  }


  .ant-table-row {

    .ant-table-cell-with-append {
      padding-left: 48px !important;
    }
  }

  .ant-table-tbody {

    .ant-table-cell {
      height: 50px;
      line-height: 50px;

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }


  .ant-table-row-level-1 .ant-table-cell{
    background-color: transparent !important;

    .ant-table-cell-ellipsis {
      padding-left: 20px !important;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent !important;
    }
  }


  .ant-table-expanded-row-level-1 .ant-table-cell {
    padding-left: 20px !important;
  }


  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }
}

.adminTable.narrow tr .ant-table-cell {
  padding-right: unset !important;
}

.ant-table-empty .ant-table-body {
   height: unset;
}