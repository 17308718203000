@import 'src/sass/variables';
.selectAnt {

  &:hover {
    border-color: $color-candlelight !important;
  }

  .ant-select-selection-item {
    line-height: 40px !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent !important;
    border-right-width: 1px !important;
  }
}

.selectAnt.disabled {
  background-color: $color-wild-sand !important;
}