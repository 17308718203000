@import 'src/sass/variables';

.subText {
  position: relative;
  width: 100%;
  color: $color-dark-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-top: 30px;
  user-select: none;
  cursor: default;

  .label {
    padding: 0 10px;
    background-color: $color-white;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    top: 50%;
    transform: translateY(50%);
    background-color: $color-gallery;
    z-index: -1;
  }
}

.google {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
  transition: border-color .2s ease-in;

  .text {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    color: $color-black;
  }

  &:hover {
    border-color: $color-dark-gray;
  }
}
