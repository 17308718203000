@import "../../../../../sass/variables";

.couponInfo {
  background-color: $color-white;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
  font-family: $project-font-family, sans-serif !important;
  font-size: $upload-image-font-size;

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid $color-white;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-picker {
    width: 400px;
    height: 40px !important;
  }


  .ant-form-item-control-input-content {

    .inputs-from .ant-input {
      height: 40px !important;
      padding: 10px 15px !important;
      color:$color-mine-shaft;
      border: 1px solid $color-gallery;
      border-radius: 4px !important;

    }
  }

  .ant-select {
    height: 40px !important;

    .ant-select-selector {
      height: 38px !important;
      border-radius: 4px !important;
      padding: 0 15px !important;

      .ant-select-selection-search {
        height: 40px !important;
      }

      .ant-select-selection-search-input {
        height: 40px !important;
      }

      &:hover {
        box-shadow: none !important;
        border-radius: 4px !important;
      }
    }
  }

  #description, #condition {
    padding: 10px 15px !important;
    min-height: 100px !important;

    &:hover {
      border: 1px solid $color-gallery !important;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
  }

  .ant-select-item-option-selected{
    color: $color-mine-shaft !important;
    font-weight: 500;
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }


  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selection-placeholder {
    line-height: 35px !important;
  }
}

.labelStatic .ant-form-item-label {
  align-self: flex-start !important;
}

.selectItemCoupon .ant-col.ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {

  .ant-select-selector {
    border: none !important;
  }
}

