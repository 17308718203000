@import "../../../../sass/variables";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  font-style: normal;
  color: $color-black;
  padding: 10px 15px;
  transition: 0.2s background-color ease-in;
  border-radius: 10px;
  background-color: rgba($color-gallery, 0);
  white-space: nowrap;

  &:hover {
    background-color: rgba($color-gallery, 0.5);
    color: $color-black;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.selected {
    background-color: rgba($color-gallery, 0.5);

    &:hover {
      background-color: rgba($color-white, 0.5);
    }
  }
}