@import '../../../../sass/variables';
.hours {

  .ant-radio-checked {

    .ant-radio-inner {
      border-color: $color-mine-shaft !important;

      &:after {
        background-color: $color-mine-shaft !important;
      }
    }
  }
}