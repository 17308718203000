@import '../../../../../sass/variables';

.formItem {
  position: relative;
}

.input {
  border: 1px solid $color-gallery !important;

  &:hover {
    border: 1px solid $color-candlelight !important;
  }
}

.inputError {
  border: 1px solid  $color-guardsman-red !important;
}

.errorMessage {
  position: absolute;
  bottom: -17.5px;
  left: 1px;
  font-size: 10px;
  color: $color-guardsman-red;
}
