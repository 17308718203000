@import 'src/sass/variables';

.path {

  & a,
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $color-black;
    cursor: pointer;
    margin: 0 12px;

    &:hover {
      text-decoration: underline;
    }

    &.last {
      cursor: default;
      margin-right: 0;
      color: $color-silver-chalice;

      &:hover {
        text-decoration: none;
      }
    }

    &.first {
      margin-left: 0;
    }
  }
}