@import 'src/sass/variables';

.voucher {
  margin-top: 30px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 30px;

  &.mobile {
    border: unset;
    padding: unset;
    margin-top: 50px;
    border-radius: unset;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
  }

  .container {
    display: flex;
    align-items: normal;
    margin-top: 20px;

    & > svg {
      margin-top: 0.2em;
    }

    .daysContainer {
      display: flex;
      flex-direction: column;
      width: 220px;
      margin-left: 10px;
    }

    .subtitle {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }

    .info {
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }
  }
}