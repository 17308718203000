@import "src/sass/variables";

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateY(10px);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: $color-category;

    img {
        max-width: 30px;
    }
}

.nameColumn {
    max-width: 330px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text {
    margin-left: 40px;
}