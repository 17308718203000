@import 'src/sass/variables';

.main {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  & span {
    color: $color-mine-shaft;
  }

  .backTitle {
    display: flex;
    align-items: center;

    & > span {
      margin-left: 15px;
    }
  }

  .title {
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    & svg {
      color: $color-white;
    }

    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }
  }
}