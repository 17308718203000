@import 'src/sass/variables';

.tableProfileCoupons {
  font-family: $project-font-family, sans-serif;
  transform: translateY(-20px);


  .ant-table-body {
    & tbody {
      tr:first-child {
        td {
          border: none !important;
        }
      }
    }
  }

  .ant-table table {
    // table-layout: fixed !important;
    border-collapse: separate;
    border-spacing: 0 10px !important;
    background-color: $color-white !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 20px !important;
  }

  .ant-table-empty .ant-table-expanded-row-fixed {
    padding: 0;
    margin: 0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: $color-white;
  }

  .ant-table-measure-row {
    border: none !important;
  }

  .ant-table-tbody > tr {
    border: 1px solid $color-gallery !important;
    border-radius: 10px !important;
  }

  .ant-table-tbody > tr > td {
    border-top: 1px solid $color-gallery !important;
    border-bottom: 1px solid $color-gallery !important;
    font-size: 16px !important;

    &:first-child {
      border-left: 1px solid $color-gallery !important;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }

    &:last-child {
      border-right: 1px solid $color-gallery !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

  }

  .ant-table-thead .ant-table-cell {
    padding: 10px 20px;
    border: none;

    &:before {
      width: 0 !important;
    }
  }

  .ant-table-tbody .ant-table-cell {
    padding: 20px !important;
  }
}

.tableMerch {
  font-family: $project-font-family, sans-serif !important;
  line-height: 1.4;

  .ant-table-row {
    position: relative !important;
  }

  .ant-table-tbody .ant-table-row-level-0 .ant-table-cell {
    border-radius: 0 !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent !important;
  }

  .ant-table-content .ant-table-cell {
    padding-left: 0 !important;
  }
}

.tableProfileCoupons .ant-table-tbody .ant-table-cell {
  padding: 5px 20px !important;
}