@import "../../../../../../../sass/variables";

.modal-subdivisions {
  font-family: $project-font-family, sans-serif;
  transition: border-color 0.3s ease-in;
  margin: 20px 0 !important;

  .ant-modal-content {
    padding-bottom: 20px;
  }

  .ant-form-item-control {
    position: relative !important;
  }

  .ant-form-item-explain.ant-form-item-explain-connected {
    position: absolute !important;
    bottom: -25px !important;
    left: 0 !important;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: $color-guardsman-red;
  }

  .ant-form-item-explain-error {
    font-size: $error-font-size !important;
    color: $color-guardsman-red;
  }

  &:hover {
    border-color: $color-candlelight !important;
  }
}

.modal-subdivisions .ant-modal-title {
  margin-bottom: 20px !important;
}

.modal-subdivisions .selected-flag {
  background-color: $color-white !important;
}

.modal-subdivisions .react-tel-input {
  font-family: $project-font-family, sans-serif !important;
}


.modal-subdivisions .react-tel-input:hover .form-control,
.modal-subdivisions .react-tel-input:hover .flag-dropdown {
  border: 1px solid $color-candlelight !important;
}

.modal-subdivisions .ant-modal-footer .ant-btn-default {
  border: none !important;
}

.modal-subdivisions .ant-modal-footer .ant-btn-primary {
  transition: background-color 0.2s ease-in !important;
}

.modal-subdivisions .ant-modal-footer .ant-btn-primary:hover {
  background-color: $color-candlelight !important;
}

.disableBtn {
  color: $color-silver-chalice !important;
  background-color: $color-gallery !important;
}

.modal-subdivisions .ant-modal-footer .ant-btn-primary.disableBtn {

  &:hover {
    color: $color-silver-chalice !important;
    background: $color-gallery !important;
  }
}