@import 'src/sass/variables';

.tableWrapper {
  width: 100%;

  .rowOptions {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 40px;

    &::before {
      position: absolute;
      content: 'Option:';
      top: -10px;
      font-size: 12px;
    }

    & > span {
      margin-top: 10px;
    }

    .title {
      margin-top: 20px;
      color: $color-gray;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .options {
      display: flex;
      flex-direction: column;

      & span {
        margin-top: 10px;
      }
    }
  }

  .quantities {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .optionQuantities {
      display: flex;
      flex-direction: column;

      & > div {
        padding-top: 10px;
      }
    }
  }

  tr:hover {
    .remove {
      opacity: 1;
    }
  }

  .date {
    white-space: nowrap !important;
  }
}

.remove {
  transform: translateY(2px);
  display: flex;
  align-self: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.preview {
  border-radius: 10px;
  width: 100px !important;
  height: 56px !important;
  object-fit: cover;
}

.optionTitle {
  overflow: hidden;
  white-space: nowrap;
  min-width: 8% !important;
}

.removeModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    margin-top: 20px;

    :not(:first-child) {
      margin-left: 30px;
    }

    & > button {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      border-radius: 10px;
      line-height: 140%;
      padding: 10px 20px;
    }

    .removeButton {
      background-color: $color-candlelight;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: $color-candlelight-dark;
      }
    }

    .cancelButton {
      border: 1px solid $color-black;
      transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

      &:hover {
        border-color: $color-candlelight-dark;
        background-color: $color-candlelight-dark;
      }
    }
  }

  & > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }
}