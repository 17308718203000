@import 'src/sass/variables';

.container {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
  }

  .subtitle {
    margin: 20px 0 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }

  .select {
    border: 1px solid $color-gallery;
  }

  .select {
    :global {
      .ant-select-selector {
        height: 42px;
        padding: 5px 10px;
        font-size: 16px;
        color: $color-silver-chalice;
        border: 1px solid $color-gallery !important;
        border-radius: 10px !important;
      }
    }
  }
}

:global {
  .ant-select-dropdown {
    border-radius: 10px !important;
  }

  .ant-select-single {
    border-radius: 10px;
  }
}