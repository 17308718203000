@import "src/sass/variables";

.user-column-logs {
  position: relative;
  height: 100%;

  &__item {
    transform: translateY(-10px);
  }

  &__email {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    padding-right: 10px;
    color: $color-dark-gray;
  }
}
