@import "src/sass/variables";

.custom-checkbox {
  display: flex;
  align-items: center;
  column-gap: 6px;
  position: relative;
  min-height: 16px;

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }

  &__label {
    position: absolute;
    top: 2px;
    left: 24px;
    cursor: pointer;
    z-index: 1;
  }
}
