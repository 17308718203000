@import 'src/sass/variables';

.main {
  display: flex;
  flex-direction: column;

  & span {
    line-height: 140%;
    font-size: 12px;
    font-weight: 500;
    color: $color-black;
  }

  .container {
    display: flex;
    flex-direction: column;

    & + .container {
      margin-top: 30px;
    }

    .phone {
      cursor: default;
      border: none;
      width: 100%;
      height: 30px;
      padding-left: 15px;
    }

    .phoneButton {
      display: none;
    }

    .subtitle {
      color: $color-gray;
    }

    .content {
      margin: 16px 0 0 15px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

:global {
  input {
    -webkit-box-shadow: inset 0 0 0 50px $color-category;
  }
}