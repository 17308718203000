@import 'src/sass/variables';

.ant-pagination-next, .ant-pagination-prev {
  border: none !important;
}

.ant-pagination.mini {
  gap: 8px;
}

.ant-pagination.mini .ant-pagination-item {
  min-width: 22px;
  height: 22px;
  margin: 0;
  line-height: 22px;

  a {
    padding: 0;
  }
}

.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
  min-width: 22px;
  height: 22px;
  margin: 0;
  line-height: 22px;
}

.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
  border: none !important;
  background: transparent !important;

  &:hover {
    color: $color-mine-shaft !important;
    background: transparent !important;
  }
}

.ant-pagination-item {
  font-family: $project-font-family, sans-serif !important;
  font-size: $pagination-font-size;
  border: none !important;
  border-radius: 4px;

  &:hover {

    a {
      color: $color-mine-shaft !important;
    }

  }
}
.ant-pagination-item-active {

  background-color: $color-candlelight !important;

  a {
    color: $color-mine-shaft !important;
  }

}

.tableCategories {

  .ant-table-row-level-1 .ant-table-cell{
    background-color: transparent !important;

    .ant-table-cell-ellipsis {
      padding-left: 20px !important;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent !important;
    }
  }

  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-row {

    .ant-table-cell-with-append {
      padding-left: 48px !important;
    }
  }

  .ant-table-tbody {
    transform: translateY(-8px);

    tr {

      .ant-table-cell {
        font-size: $table-cell-font-size;
        background: $color-white;
        padding: 0 16px 0 0;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
