@import "src/sass/variables";


.merchant-profile-form {
  line-height: 1.4px;
  width: 400px !important;
}

.profile-merchant-label-class {
  margin-bottom: 15px !important;
  line-height: 1.4;
  color: $color-mine-shaft;
}

.profile-merchant-col {

  .ant-input-disabled {
    color: $color-mine-shaft !important;
  }
}