@import "src/sass/variables";
.row {
  padding: 0 16px 0 10px;
  column-gap: 10px;
  margin-bottom: 86px;
  flex-wrap: nowrap;
}

.name {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
}

.position {
  height: 20px;
}

.name, .position {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user {
  display: flex !important;
  flex-direction: row !important;
  padding-left: 10px;
  margin: 30px 0 34px;
}

.avatar {
  position: relative;
  width: 42px;
  height: 42px;
  background: $color-gray;
  border-radius: 5px;
  margin-right: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  bottom: -3px;
}

.icon {
  color: $color-candlelight;
}

.info {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}