.wrapper {
  margin: 150px 0;
  padding: 0 17%;

  &.mobile {
    padding: unset;
    margin: 50px 0 50px;

    & span {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  & span {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 20px;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;

    &.mobile {
      font-size: 20px;
    }
  }

  .welcome {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    &.mobile {
      margin-top: 10px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 70px;
      display: flex;
      flex-direction: column;

      :nth-child(1) {
        font-weight: 700;
        font-size: 20px;
      }
    }

    &.mobile {
      & > div {
        margin-top: 30px;

        :nth-child(1) {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}