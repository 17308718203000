@import 'src/sass/variables';

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 100%;

    .logo {
      margin-bottom: 20px;
    }

    .container {


      .title {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 140%;
        font-weight: 700;
      }

      .form {
        margin-top: 30px;

        .item {

          & label::before {
            content: unset;
          }
        }

        .input {
          height: 40px;
          border-radius: 10px;
          padding: 10px 15px;
          font-size: 14px;
          font-weight: 400;
          color: $color-black;
        }

        .button {
          margin-top: 20px;
          padding: 10px 0;
          font-size: 14px;
          line-height: 140%;
          font-weight: 500;
          color: $color-black;
          background-color: $color-candlelight;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
