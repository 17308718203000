@import "src/sass/variables";
@import "src/sass/mixins";

.container {
    position: static;
    width: 100%;
    height: 100%;
    background: $color-candlelight;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $project-font-family, sans-serif !important;
    font-weight: 500;
}
.containerZoomed {
    width: 111vw;
    height: 111vh;
}

.card {
    width: 440px;
    background-color: $color-white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 70px 70px;

    @media (max-width: 576px) {
        width: 340px;
    }
}

.form {
    position: relative;

}

.logo {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin-bottom: -20px;

    img {
        max-width: 100%;
    }

    .leftHang {
        position: absolute;
        top: 113px;
        left: 55px;
        z-index: 100;
    }

    .rightHang {
        position: absolute;
        top: 106px;
        right: 35px;
        z-index: 99;
    }
}

.formItem label {
    width: 100%;
    font-size: $label-font-size;
    color: $color-gray;
}

.remeberContainer {
    display: flex;
    justify-content: space-between;

    &:hover {

        input {
            border: 1px solid $color-candlelight !important;
        }
    }
}

.link {
    color: $color-mine-shaft;
    font-weight: 500;
    font-size: $label-font-size;
    cursor: pointer;
    padding: 0 0 0 7px;

    &:hover {
        color: $color-mine-shaft;
    }
}

.formInput {
    position: relative;

    input {
        &:focus,  &:hover{
            border: 1px solid $color-candlelight !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.password,
.userName {
    position: relative;
}

.labelPassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

label:has(> .labelPassword) {
    width: 100%;
}

.eye {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: $color-silver-chalice;
}

[title="Email or username"], [title="Password"] {
    color: $color-silver-chalice !important;
    height: 0 !important;
}


.error {
    position: absolute;
    border-top: 0;
    left: 0;
    color: $color-guardsman-red;
    text-align: left;
}
.inputError {
    border: 1px solid $color-guardsman-red !important;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(162, 162, 162, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    font-family: $project-font-family, sans-serif;
    width: 100% !important;
    height: 40px !important;
    transition: background-color 0.2s ease-in !important;
    background: $color-candlelight !important;
    outline: none;
    border-radius: 4px;
    color: $color-black !important;
    border: 1px solid $color-candlelight !important;

    &:hover {
        background-color: $color-candlelight !important;
        outline: none !important;
        border: 1px solid $color-candlelight !important;
    }
}

.checkbox:hover {

    input {
        border: 1px solid grey !important;
    }
}

