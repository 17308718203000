.item {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      display: flex;
      align-items: center;

      .title {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .arrow {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}