@import "src/sass/variables";
.containerAnt {

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid $color-white;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-input {
    background-color: transparent !important;

    &:focus,
    &:hover {
      border: 1px solid $color-candlelight !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .passwordInput .ant-input{

    &:focus,
    &:hover {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .passwordInput.ant-input-affix-wrapper-focused {
    border: 1px solid $color-candlelight !important;
  }

  .ant-btn[disabled] {
    background-color: $color-gallery !important;
    outline: none !important;
    border: 1px solid $color-gallery !important;

    &:hover {
      background-color: $color-gallery !important;
      border: 1px solid $color-gallery !important;
    }
  }

  .ant-checkbox-inner {
    width: 16px !important;
    height: 16px !important;
    border-radius: 4px !important;
    border: 1.3px solid $color-silver-chalice !important;
  }

  .ant-form-item-control:hover {

    .ant-checkbox-inner {
      border: 1.3px solid $color-candlelight !important;
    }

  }
}

#password {

  input {
    padding-right: 35px;
  }
}

.sing-in-card {
  background-color: $color-white;
  padding: 43px 70px 70px;
  border-radius: 4px;
  max-width: 440px;

  &__image {
    margin-bottom: 30px;
  }

  &__title {
    color: $color-mine-shaft;
    font-size: $title-font-size;
    line-height: 19.6px;
    padding: 0;
    font-weight: 700;
    margin: 0 0 30px 0;
  }

  &__text-section {
    margin-bottom: 20px;
  }

  &__text {
    color: $color-mine-shaft;
    font-size: $usual-font-size;
    font-weight: 400;
    padding: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__action-btn {
    width: 100%;
  }
}
