@import 'src/sass/variables';

.main {
  width: 100%;
  border: 1px solid $color-gallery;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;

  &.mobile {
    margin-top: 30px;
    border-color: $color-black;

    &.fixed {
      border: none;
      margin-top: unset;
    }
  }

  .header {
    cursor: pointer;
    margin: 20px;
    border: 1px solid $color-black;
    border-radius: 10px;
    min-height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: background 0.2s ease-in, border-color 0.2s ease-in;

    &.mobile {
      margin: unset;
      height: 42px;
      border: none;

      & > span {
        font-weight: 500 !important;
      }
    }

    &:hover {
      border-color: $color-candlelight;
      background-color: $color-candlelight;
    }

    &:active {
      transition: none;
      background-color: $color-white;
    }

    & span {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 600 !important;
    }
  }
}
