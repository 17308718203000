@import "src/sass/variables";

.vatInput {
    width: 400px;
}

.button {
    color: $color-mine-shaft;
    font-size: $button-font-size;
    font-weight: 500;
    width: 72px;
    height: 40px;
    margin-left: 328px;
    border-radius: 4px;
    background-color: $color-candlelight !important;
    transition: background-color 0.2s ease-in;
    border: none;
    outline: none;

    &:hover {
        background-color: $color-candlelight-dark !important;
    }

    &:active {
        background-color: $color-candlelight-dark !important;
        color: $color-mine-shaft !important;
    }

    &:focus {
        background-color: $color-candlelight-dark !important;
        color: $color-mine-shaft !important;
    }
}

.button:hover {
    color: $color-mine-shaft;
}

.button:focus {
    color: $color-mine-shaft;
}

