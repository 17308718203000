@import "src/sass/variables";

.partners-banner-container{
  width: 100%;
  margin-bottom: 150px;

  &_mobile {
    margin-bottom: 70px;
  }
}

.partners-banner-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 140%;
  max-width: 1600px;
  width: 84.4%;
  margin: 0 auto 50px;

  &_mobile {
    margin-bottom: 20px;
  }
}

.partners-banner {
  width: 84.4%;
  max-width: 1600px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
}



