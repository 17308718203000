@import "../../../../../sass/variables";
.container {
  width: 100%;
}

.btnAddOption {
  height: 42px!important;
  font-size: $button-font-bigger-size !important;
  color: $color-mine-shaft!important;
  font-weight: 500!important;
  display: flex!important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  border: 1px dashed $color-mine-shaft !important;
}