@import "src/sass/variables";

.children {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: $color-silver-chalice;
  margin-bottom: 4px;
  user-select: none;
}
