@import 'src/sass/variables';

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px 15px;
  background-color: $color-alabaster;

  & > span {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    line-height: 140%;
  }

  & > div {
    width: 100%;
    display: flex;
    margin-top: 50px;
    height: 42px;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 460px) {
      width: 65%;
    }

    & > button {
      background-color: $color-candlelight;
      border-radius: 10px;
      height: 100%;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      margin-left: 10px;
      transition: background-color 0.2s ease-in;
      width: 120px;

      &:hover {
        background-color: $color-candlelight-dark;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    & > input {
      max-width: 384px;
      width: 100%;
      padding-left: 15px;
      height: 100%;
      border-radius: 10px;
      border: 1px solid $color-gallery;
    }
  }

  &.mobile {
    padding: 50px 15px;

    @media screen and (min-width: 460px) {
      padding: 50px 0;
    }

    & > div {
      flex-direction: column;
      height: unset;
      margin-top: 30px;

      & > button {
        height: unset;
        width: 100%;
        margin-top: 10px;
        margin-left: unset;
      }

      & > input {
        height: 42px;
        max-width: unset;
      }
    }
  }
}