@import 'src/sass/variables';

.main {
  position: relative;
  width: 100%;
  height: 211px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    background-color: rgba($color-white, 0.5);
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background-color: rgba($color-white, 0.3);
    }

    &:active {
      transition: none;
      background-color: rgba($color-white, 1);
    }
  }

  .image {
    transition: none;
    user-select: none;
    border-radius: 10px 10px 0 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .prevButton {
    transition: opacity .3s ease-in;
    z-index: 1;
    left: 10px;
    opacity: 1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    & svg {
      position: relative;
      left: -1px;
    }
  }

  .nextButton {
    transition: opacity .3s ease-in;
    z-index: 1;
    right: 10px;
    opacity: 1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    & svg {
      position: relative;
      right: -1px;
    }
  }
}