@import 'src/sass/variables';

.main {
  flex: 0 0 auto;
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $color-gallery;
  padding: 30px 0 0;
  width: 100%;
  align-self: flex-end;

  &.mobile {
    width: 100%;
    margin-bottom: 30px;
    align-items: center;

    @media screen and (min-width: 460px) {
      align-self: center;
    }
  }

  .container {
    width: 84.4%;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    &.mobile {
      width: 100%;
      margin: unset;
      padding: 0 10% 30px 4%;

      & > div:nth-child(2) {
        width: 155.95px;
      }

      &.llll {
        flex-direction: column;
      }

      @media screen and (min-width: 460px) {
        width: 65%;
        padding-left: unset;
        padding-right: unset;
      }
    }
  }

  .label {
    text-align: center;
    padding: 10px;

    &.mobile {
      font-size: 10px;
      padding: 20px;
    }
  }
}