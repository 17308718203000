.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6%;
  overflow: hidden;

  @media screen and (max-width: 1330px){
    margin-left: 10px;
  }
}