@import './src/sass/variables';

.header {

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    font-size: $title-coupon-font-size;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__right {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
  }

  .btn-exel {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    background-color: $color-white !important;
    border: 1px solid $color-gallery !important;
  }

  .searchInput {
    border-color: $color-gallery !important;
  }
}
