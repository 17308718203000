@import '../../sass/variables';

.modal {
  background-color: rgba($color-black, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2;

  &.mobile {
    opacity: 1;
    background-color: transparent;
    z-index: 4;
    align-items: flex-end;

    &.active {
      pointer-events: all;
      background-color: rgba($color-black, 0.5);
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    padding: 30px;
    border-radius: 10px;
    width: 460px;
    background-color: $color-white;

    &.mobile {
      position: relative;
      transform: translateY(100%);
      width: 100%;
      max-height: 85%;
      overflow: auto;
      border-radius: 10px 10px 0 0;
      padding: 20px 15px;
      transition: 0.2s transform ease-out, 0.2s opacity ease-in;

      &.active {
        transform: translateY(0);
      }
    }
  }
}