@import "../../../sass/variables";

.disabled {
  color: rgba($color-black, 0.25) !important;
  background-color: $color-wild-sand !important;
  transition: background-color 0.3s ease-in !important;
}

.suffixInput {
  display: flex !important;
  height: 40px;
  padding: 0 15px !important;
  line-height: 40px;
  border: 1px solid $color-gallery;
  border-radius: 4px !important;
  transition: border-color 0.3s ease-in !important;

  &:hover {
    border-color: $color-gorse !important;
  }

  &:focus-within {
    border-color: $color-gorse !important;
    box-shadow: 0 0 0 2px rgb($color-candlelight, 0.2) !important;
  }
}

.input {
  font-size: $input-font-size !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.input {
  -webkit-box-shadow: none !important;
}

.suffix {
  font-size: $input-font-size !important;
  padding: 0 !important;
  text-align: left !important;
}

.errStyle {
  border: 1px solid $color-guardsman-red !important;
}
