@import '../../../sass/variables';
.buyers-table {
  height: 100% !important;
}

.tableBuyers {
  height: 100% !important;

  & input {
    -webkit-box-shadow: unset !important;
  }

  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-tbody {

    tr {

      .ant-table-cell {
        font-size: $table-cell-font-size;
        background: $color-white;
        padding: 0;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
