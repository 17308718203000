@import 'src/sass/variables';

.menuFilterWrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(20, 20, 20, 0.05);
  padding: 10px 0;
  position: relative;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;


  &__item {
    display: flex;
    column-gap: 10px;
    padding: 12px 15px;
    cursor: pointer;



    .ant-checkbox-wrapper {
      width: auto;
    }

    .ant-checkbox-inner {
      border-color: $color-mine-shaft;
      border-radius: 4px;
    }

    &:hover{
      background-color: $color-wild-sand;
    }
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
