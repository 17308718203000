@import 'src/sass/variables';

.edit-merchant-password {
  position: relative;
  margin: 10px 0;
}

.button-change-password svg {
    margin-right: 8.5px;
  }
