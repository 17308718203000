@import 'src/sass/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .link {
    display: block;
    width: 100%;
    padding: 10px 0 20px;
    line-height: 140%;
    color: $color-black;

    & > svg {
      height: 12px;
      margin-right: 10px;
    }
  }

  .content {
    flex: 1 1 auto;
    width: 100%;

    .preview {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      border-radius: 10px 10px 0 0;
    }

    .description {
      border: solid $color-gallery;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 10px 10px;
      padding: 15px;

      &.option {
        border-width: 1px;
        border-radius: 10px;
      }

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $color-black;
      }

      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .subtitle {
          font-size: 12px;
          font-weight: 500;
          color: $color-dark-gray;
          line-height: 140%;
        }

        .data {
          font-size: 14px;
          font-weight: 500;
          color: $color-black;
          line-height: 140%;
        }
      }
    }
  }

  .bottom {
    text-align: center;

    .backLink {
      margin-top: 10px;
      display: block;
      width: 100%;
      font-weight: 500;
      border: 1px solid $color-black;
      border-radius: 10px;
      padding: 10px 0;
      line-height: 140%;
      color: $color-black;
    }

    .button {
      width: 100%;
      font-weight: 500;
      background-color: $color-candlelight;
      padding: 10px 0;
      border-radius: 10px;
      bottom: 0;
      transition: 0.2s background-color ease-in;

      &:active {
        background-color: $color-candlelight-dark;
      }
    }
  }
}