@import 'src/sass/variables';

.visible-on-voucher {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__booking-item {
    margin-bottom: 0 !important;
  }

  &__title {
    font-weight: 400;
    margin: 0;
  }

  &__add-info {

    p {
      font-size: $label-font-size;
      color: $color-silver-chalice;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
}
