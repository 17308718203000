/************COLORS***********/
$color-silver-chalice: #A8A8A8;
$color-candlelight: #F9E218;
$color-candlelight-light: #FFEC66CC;
$color-candlelight-dark: #F9C718;
$color-gallery: #EDEDED;
$color-dark-gray: #8E8E8E;
$color-white: #fff;
$color-black: #333333;
$color-red: #FF0000FF;
$color-gray: #8E8E8E;
$color-mine-shaft: #333333;
$color-green: #0BAE2F;
$color-category: #FBFBFB;
$color-shadow-dark: #1414140C;
$color-follow-icon: #3B5998;
$color-blue: #4081FF;
$color-alabaster: #FBFBFB;
$color-guardsman-red: #D00000;
$color-mine-shaft: #333333;
$color-white: #FFFFFF;
$color-white-dark: #FBFBFB;
$color-wild-sand: #F5F5F5;
$color-alto: #d9d9d9;
$color-gorse: #FFF242;
$color-mercury:#E5E5E5;

$title-font-size: 20px;
$usual-font-size: 14px;
$title-coupon-font-size: 20px;
$data-picker-font-size: 16px;
$upload-image-font-size: 14px;
$radio-font-size: 14px;
$input-big-font-size: 16px;
$input-font-size: 14px;
$button-font-bigger-size: 16px;
$button-font-size: 14px;

$link-font-bigger-size: 16px;

$table-header-font-size: 12px;
$pagination-font-size: 12px;

$table-cell-font-size: 14px;
$label-big-font-size: 16px;
$label-font-size: 12px;
$error-font-size: 10px;
$error-font-little-size: 10px;

$modal-title-fontSize: 26px;
$modal-body-fontSize: 16px;
$modal-small-fontSize: 12px;

$text-modal-title-fontSize: 26px;
$text-modal-content-fontSize: 16px;
$text-modal-label-fontSize: 12px;

$edit-info-title-fontSize: 26px;
$project-font-family: 'Inter';
$table-profile-font-size: 12px;

$text-title-font-size: 26px;
$text-font-size: 16px;
$text-usually-font-size: 14px;
$text-little-font-size: 12px;

$checkbox-font-size: 16px;

$slider-max-image-width: 1600px;
$tab-font-size: 12px;


