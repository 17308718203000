@import 'src/sass/variables';

.gallery {
  width: 400px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.upload-images-gallery {
  font-family: $project-font-family;

  input {
    display: none;
  }
}

.delete-image {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  background-color: rgba($color-white, 0.75);
  color: $color-mine-shaft;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.upload-images-gallery__image {
  width: 195px;
  height: 109px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: grab;
    transition: background 0.2s ease-in;
    z-index: 1;
  }
}

.upload-images-gallery__image:hover .overlay{
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba($color-black, 0.5);
  transition: background 0.2s ease-in;
  cursor: grabbing;
}

.upload-images-gallery__image:hover .delete-image {
  opacity: 1;
}

.upload-image {
  width: 195px;
  height: 109px;
  padding: 0 48px;
  border: 1px dashed $color-mine-shaft;
  border-radius: 4px;
  background-color: $color-gallery;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &__title {
    font-weight: 500;
    color: $color-mine-shaft;
    margin-bottom: 10px;
    font-size: $text-usually-font-size;

    span {
      text-decoration: underline;
    }

    &__count {
      font-size: $text-little-font-size;

    }
  }
}
.upload-image.full {
  width: 400px;
  height: 150px;
}

