@import 'src/sass/variables';

.container {
  width: 100%;

  .couponsBlock {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(290px, 377px));
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 30px;
    margin-left: 30px;

    @media screen and (max-width: 1330px) {
      grid-template-columns: repeat(2, minmax(290px, 377px));
    }

    @media screen and (min-width: 1921px) {
      grid-template-columns: repeat(3, minmax(290px, 377px));
    }

    &.mobile {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(1, minmax(250px, 440px));
      grid-gap: 20px;
      width: 100%;
      margin-left: unset;

      @media screen and (min-width: 460px){
        grid-template-columns: repeat(1, minmax(250px, 100%));
      }
    }
  }

  .loadMore {
    position: relative;
    bottom: -50px;
    left: 50%;
    margin-bottom: 100px;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 193px;
    height: 42px;
    border: 1px solid $color-mine-shaft;
    border-radius: 10px;
    transition: background 0.2s ease-in, border-color 0.2s ease-in;

    &:hover {
      background-color: $color-candlelight;
      border-color: $color-candlelight;
    }

    & > span {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }

    &.mobile {
      &:hover {
        background-color: unset;
        border-color: $color-black;
      }

      &:active {
        background-color: $color-candlelight;
        border-color: $color-candlelight;
      }
    }
  }

  .arrowUp {
    z-index: 1;
    cursor: pointer;
    position: fixed;
    width: 60px;
    height: 60px;
    border: 2px solid $color-black;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    right: 43px;
    opacity: 0;
    pointer-events: none;
    background-color: $color-white;
    transition: background 0.2s ease-in, opacity 0.2s ease-in;

    &:hover {
      background-color: $color-candlelight;
    }

    &:active {
      transition: none;
      background-color: $color-white;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
