@import "../../../../../sass/variables";

.payment-body {
  padding: 20px 0 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__bottom {
    display: flex;
    column-gap: 10px;

    .payment-body__item {
      flex: 0 1 50%;
    }
  }

  &__label {
    display: block;
    font-size: $text-modal-label-fontSize;
    font-weight: 500;
    color: $color-gray;
  }

  .StripeElement {
    border: 1px solid $color-gallery;
    padding: 10px 15px;
    border-radius: 10px;
  }
  .StripeElement--focus {
    border: 1px solid $color-candlelight;
  }
}
