.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .reader {
    width: 100%;
    height: auto;

    & > div:first-child {
      display: none;
    }
  }
}