@import "../../../sass/variables";

.dropdown-btn {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1.4 !important;

  &.with-children {
    padding-left: 1px;
    justify-content: flex-start;
    overflow: hidden;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .ant-btn {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: $color-silver-chalice;
    height: 100%;
  }

  .ant-btn-default {
    display: none;
  }

  .ant-dropdown-trigger {
    display: block;
    height: 50px;
    padding: 0;
    margin: 0;

    svg {
      transform: translateY(2px);
      line-height: 1.4 !important;
    }
  }

}

.dropdown {
  width: 150px;
  padding: 5px 0;

  ul {
    padding: 0;
  }
}