@import "../../../../../sass/variables";

.failed-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $text-modal-content-fontSize;
  font-family: $project-font-family, sans-serif;
  margin-bottom: 20px;
  border: 1px solid $color-gallery;
  color: $color-mine-shaft;
  padding: 20px;
  border-radius: 10px;

  &.mobile {
    font-size: 14px;
    line-height: 140%;
    font-weight: 400;
  }

  p {
    text-align: center;
    margin: 0;
  }
}
