@import 'src/sass/variables';

.item {
  border: 1px solid $color-gallery;
  border-radius: 10px;

  &.disabled {
    pointer-events: none;
    background-color: $color-alabaster;

    & span,
    svg {
      color: $color-gray;
    }
  }

  & span {
    line-height: 140%;
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
  }

  & + .item {
    margin-top: 20px;
  }

  .upper {
    padding: 15px;

    .title {
      font-weight: 400;
      font-size: 14px;
    }

    .info {
      margin-top: 11px;
      display: flex;
      flex-direction: column;

      .util {
        display: flex;
        justify-content: space-between;

        & > div {
          display: flex;
          align-items: center;

          & > svg {
            margin-right: 5px;
          }
        }

        & + .util {
          margin-top: 8px;
        }

        .subtitle {
          color: $color-gray;
        }

        .data {
          font-size: 14px;
        }
      }
    }
  }

  .buttons {
    display: flex;
    padding: 15px;
    justify-content: space-between;

    & > div,
    button {
      display: flex;

      .downloadSubtitle {
        font-weight: 400;
      }

      & > span {
        margin-left: 5px;
      }
    }

    .icons {
      color: $color-gray;

      & > div + div {
        margin-left: 20px;
      }
    }
  }
}

.divider {
  width: 100%;
  margin-bottom: unset;
}