@import "../../../../../sass/variables";

.merchant-table {
  height: 100%;

  .react-tel-input input{
    box-shadow: none;
  }

  .ant-btn-icon-only.ant-dropdown-trigger {
    background: transparent !important;
    box-shadow: none;
  }


  .ant-table-row {

    .ant-table-cell-with-append {
      padding-left: 48px !important;
    }
  }

  .ant-table-row-level-1 .ant-table-cell{
    background-color: transparent !important;

    .ant-table-cell-ellipsis {
      padding-left: 20px !important;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent !important;
    }
  }


  .ant-table-expanded-row-level-1 .ant-table-cell {
    padding-left: 20px !important;
  }


  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }
}

