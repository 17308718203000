@import '../../sass/variables';

.number {

  :global(.ant-form-item-control) {

    :global(.ant-form-item-control-input-content) {
      display: flex !important;
      align-items: center !important;
      gap: 20px !important;

      :global(.ant-btn-text) {
        padding: 0 !important;
        border: none !important;
        background: none !important;

        span:not(:global(.anticon)) {
          margin-left: 10px !important;
        }
      }
    }
  }
}

.discount, .quantity {
  display: grid !important;
  grid-template-columns: 1fr 267px !important;
  align-items: center !important;
  row-gap: 10px !important;
  margin-top: 6px !important;
  width: 100%;

  :global(.ant-input-affix-wrapper) {
    padding-left: 15px !important;

    :global(.ant-input) {
      height: 40px !important;
    }
  }

  span:not(:global(.ant-radio)) {
    padding: 0 0 0 10px;
  }
}

.tied {

  :global(.ant-form-item-label) {
    padding-bottom: 10px !important;
  }

  :global(.ant-form-item-control-input-content) {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
  }

  :global(.ant-radio-group) {
    position: absolute !important;
    z-index: 1 !important;
    transform: translate(-34px, -32px) !important;
  }

  :global(.ant-select-selector) {
    height: 40px !important;
    border-radius: 4px !important;
  }
}

.note {
  position: relative;
  :global(.ant-form-item-control-input-content) {
    height: fit-content !important;
  }

  textarea:global(.ant-input) {
    border-radius: 4px !important;
    padding: 10px 15px !important;
    min-height: 130px !important;
  }
}

.select {

  :global(.ant-select-selector) {
    padding: 0 15px !important;

    :global(.ant-select-selection-item) {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.selectError {

  :global(.ant-select-selector) {
    padding: 0 15px !important;
    border: 1px solid $color-guardsman-red !important;
  }
}

.status {
  width: 100%;

  div {
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
    width: 100%;
  }
}


:global(.ant-select-selector) {
  :global(.ant-select-selection-placeholder) {
    display: flex !important;
    align-items: center !important;
  }
}

.tiedRadio {
  display: flex !important;
  margin-left: 98px;
}

.date {
  width: 100%;

  &:hover {
    border: 1px solid $color-candlelight !important;
  }
}

.dateError {
  border: 1px solid $color-guardsman-red !important;
}

.inputContainerTwo, .inputContainer {
  position: relative;
}
.inputContainer {
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.btnModal {
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
}

.btn {
  color: $color-mine-shaft !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  transition: background-color 0.2s ease-in;
}
.btn.send:hover {
  background-color: $color-candlelight !important;
}

.cancel {
  border: none !important;
  background-color: transparent !important;

  &:hover {
    color: $color-mine-shaft !important;
  }
}

.radioPercents {
  transform: translateY(-5px);
}
.formItem {
  position: relative;
}

.errorInput {
  border: 1px solid $color-guardsman-red !important;
}

.errorText {
  position: absolute;
  bottom: -17.5px;
  left: 2px;
  font-size: 10px;
  color: $color-guardsman-red;
}
.errorText.suf {
  left: -8px;
}