@import 'src/sass/variables';

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 116px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;

  &.mobile {
    height: unset;
    padding: 15px;
    margin-top: 15px;

    .mobilePricesWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: $color-silver-chalice;
  }

  .priceContainer {
    margin-top: 10px;
    display: flex;
    font-weight: 600;
    font-size: 16px;

    & span {
      font-size: 16px;
      line-height: 140%;
    }

    .discount {
      margin-left: 10px;
      color: $color-green;
    }
  }

  .oldPrice {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: $color-silver-chalice;
    text-decoration-line: line-through;
  }
}