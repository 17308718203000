@import '../../../../../../../sass/variables';


.telContainer .react-tel-input .form-control {
  width: auto !important;
}

.telContainer .react-tel-input .flag-dropdown {
  display: none;
}

.telInput {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.dropDays {
  display: flex;
  column-gap: 8px;
}

.timeDrop {
  display: inline-block;
  height: 32px;
}

.arrowDrop {
  display: inline-block;
  position: relative;
  transform: translateY(-6px);
}

.arrowDrop .ant-btn {
  background: transparent !important;
}

.arrowDrop .ant-btn:hover, .ant-btn:focus {
  color: $color-silver-chalice;
  background: transparent;
}

.dropDays .ant-btn:empty {
  display: none !important;
}

.dropDays .ant-btn-icon-only {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45px;
  border: none !important;
  color: $color-silver-chalice;
}
