@import '../../../../../../../../../sass/variables';

.dayName {
  color:$color-silver-chalice;
}

.allDays {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.dropdownContainer {
  display: flex;
  justify-content: space-between;
}

.dayTime {
  display: flex;
  column-gap: 5px;
}