@import '../../sass/variables';

.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  & span {
    line-height: 140%;
    font-weight: 400;
    font-size: 12px;
    color: $color-black;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .back {
    display: flex;
    padding-bottom: 20px;
    align-items: center;

    & > span {
      font-weight: 500;
      font-size: 14px;
      margin-left: 15px;
    }
  }

  .input {
    margin-top: 10px;
    border-radius: 10px;
  }

  .pickers {
    display: flex;
    margin-top: 10px;

    .picker {
      border-radius: 10px !important;
      max-width: 100%;

      & svg {
        color: $color-gray;
      }

      & + .picker {
        margin-left: 10px;
      }
    }
  }

  .modalTitle {
    width: 100%;
    position: relative;
    text-align: center;

    & > span {
      font-size: 16px;
      font-weight: 600;
    }

    .icon {
      color: $color-gray;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;

    }
  }

  .buttons {
    & > label {
      font-size: 14px;
      font-weight: 400;

      :nth-child(2) {
        margin-left: 5px;
      }

      & + label {
        margin-top: 10px;
      }
    }
  }
}

.extra {
  width: 100%;
  display: flex;
  justify-content: space-around;

  & > button {
    border-radius: 10px;
    padding: 10px 32px;
    line-height: 140%;
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s background-color ease-in;

    &:active {
      background-color: $color-candlelight;
    }
  }

  .set {
    background-color: $color-candlelight;

    &:active {
      background-color: $color-white;
    }
  }
}

.closer {
  z-index: 5;
  position: fixed;
  height: 100%;
  width: 100%;
}

.dropdown {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 5 !important;

  & > div {
    border-radius: 10px;
  }
}

.extraModal {
  opacity: 0;
  pointer-events: none;
}