@import '../../../../../../../sass/variables';

.tableMerch {
  margin-bottom: 8px;

  .ant-table-empty .ant-table-expanded-row-fixed {
    padding: 0;
    margin: 0;
  }

 .ant-table-thead .ant-table-cell {
    padding-left: 0 !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 2px 15px 2px 0 !important;
  }
}

.tableMerch {
  font-family: $project-font-family, sans-serif !important;

  .ant-table-row {
    position: relative !important;
  }

  .ant-table-tbody .ant-table-row-level-0 .ant-table-cell{
    border-radius: 0 !important;
    border-bottom: 1px solid $color-silver-chalice !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent !important;
  }

  .ant-table-content .ant-table-cell {
    padding-left: 0 !important;
  }
}
