@import "../../../../../../../../sass/variables";

.dropdown {
  min-width: 250px !important;
}

.dropdown ul {
  max-height: 500px !important;
  overflow-y: auto !important;
}

.drop {
  position: relative;
  min-width: 45px !important;
}

.dropdownBtn {
  width: 100%;
  height: 100%;
  border: none !important;
}

.menu {
  display: flex !important;
  flex-direction: column !important;
  row-gap: 20px !important;
  padding: 15px !important;
}

.numDays {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  pointer-events: none;
  color: $color-silver-chalice;
}
