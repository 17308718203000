@import 'src/sass/variables';

.table table thead {
  transform: translateY(10px);
}

.table table thead tr th {
  background-color: $color-white !important;
  color: $color-gray;
  font-size: $table-profile-font-size;
}