@import "../../../sass/variables";

.controls {
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.addButton {
    color: $color-mine-shaft !important;
    padding: 10px 20px !important;
    font-size: $button-font-size !important;
    font-weight: 500 !important;
    margin-right: 16px !important;
}

.addButton:hover {
    color: $color-mine-shaft !important;
}

.addButton:focus {
    color: $color-mine-shaft !important;
}

.title {
    font-size: $title-font-size;
    font-weight: 700;
    line-height: 140%;
}
