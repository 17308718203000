.upload {

  :global(.ant-form-item-control) {
    height: 100px !important;

    :global(.ant-form-item-control-input) {
      height: 100px !important;

      :global(.ant-form-item-control-input-content) {
        height: 100px !important;

        :global(.uploadImageContainer) {
          height: 100px !important;

          :global(.ant-upload-select-picture-card) {
            height: 100px !important;
          }
        }
      }
    }
  }
}

.publishedLabel {
  margin-left: 10px;
}
