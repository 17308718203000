.main {
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    margin: 0 7.8% 30px;
    padding-top: 6.4%;
    max-width: 1600px;

    &.zoomed {
      padding-top: 2.8%;
    }
  }
}