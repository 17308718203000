@import 'src/sass/variables';

.cart {
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    position: relative;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0.2em;
    white-space: nowrap;

    &:after {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-black;
      transform: scaleX(0);
      transition: 0.1s;
    }
  }

  &:hover > span:after {
    transform: scaleX(1);
  }
}