@import './src/sass/variables';

.formItem {
  margin-bottom: 15px;
  color: $color-mine-shaft !important;
  font-family: $project-font-family, sans-serif !important;
  position: relative !important;


  label {
    font-size: $label-font-size;
    color: $color-gray;
    font-weight: 500;
  }
}
.formItemEmail {
  margin-bottom: 22px;
}

.btnModal {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.btn {
  color: $color-mine-shaft !important;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  transition: background-color 0.2s ease-in;
  border: none !important;
  outline: none !important;
  border-radius: 4px !important;
}

.btn.send:hover {
  background-color: $color-candlelight-dark !important;
}

.cancel {
  border: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.formItemRadio {
  flex-direction: row !important;
  column-gap: 50px !important;
}
