@import 'src/sass/variables';


.signUp {
  width: 460px;
  margin: 0 auto;
  padding: 30px;
  font-family: Inter, sans-serif;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  font-size: $button-font-bigger-size;
  font-weight: 600;
  line-height: 140%;
  padding: 10px 20px;
  transition: background 0.3s ease-in;
  border-radius: 10px;
  background-color: $color-candlelight;
  margin-top: 0.2em;
  white-space: nowrap;

  &:hover {
    background-color: $color-candlelight-dark;
  }
}

.singUpContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: $text-font-size;

  .title {
    font-weight: 700;
    font-size: $text-modal-title-fontSize;
    margin-bottom: 30px;
  }

  .cross {
    cursor: pointer;
  }
}

.form {
  margin-bottom: 20px;

  & label {
    color: $color-silver-chalice !important;
    font-size: $label-font-size;

    &:before {
      display: none !important;
    }
  }

  .item {
    margin-bottom: 30px;
  }

  .item > div {
    padding: 0;
  }
}

.input {
  height: 42px;
  width: 400px;
  border-radius: 10px !important;
  font-size: $input-big-font-size;
  line-height: 140% !important;
  font-weight: 400;
}

.submitButton {
  width: 100%;
  border-radius: 10px !important;
  height: 42px !important;
  color: $color-black !important;
  padding: 10px 20px;
  font-size: $button-font-bigger-size;
  font-weight: 600;
  line-height: 140%;
  background-color: $color-candlelight;
  transition: border-color 0.2s ease-in, background-color 0.2s ease-in;
  opacity: 1;

  &:hover {
    border-color: $color-candlelight-dark;
    background-color: $color-candlelight-dark;
  }
}

.util {
  display: inline-block;
  width: 100%;
  font-size: $text-font-size;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  user-select: none;
  color: $color-silver-chalice;
}

.terms {
  display: inline-block;
  font-size: $text-modal-content-fontSize;
  font-weight: 400;
  line-height: 140%;
  color: $color-silver-chalice;

  .rules {
    cursor: pointer;
    display: inline-block;
    position: relative;
    color: $color-black;

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      transition: background 0.1s ease;
      background-color: rgba($color-black, 1);
    }

    &:hover::after {
      background-color: rgba($color-black, 0);
    }
  }
}

input {
  -webkit-box-shadow: inset 0 0 0 50px $color-white;
  -webkit-text-fill-color: $color-black;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.or {
  user-select: none;
  font-size: $text-font-size;
  display: inline-block;
  color: $color-gray;
  width: 100%;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-top: 30px;
}

.signIn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $text-modal-content-fontSize;
  color: $color-mine-shaft;
  width: 100%;
  height: 42px;
  border: 1px solid $color-black;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 10px;
  transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

  &:hover {
    background-color: $color-candlelight-dark;
    border-color: $color-candlelight-dark;
    color: $color-mine-shaft;
  }
}
