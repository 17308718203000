@import '../../sass/variables';

.main {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 100px;
      height: 100px;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: $color-black;
      margin-top: 30px;
    }

    .subtitle {
      text-align: center;
      width: 100%;
      padding: 20px;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      border: 1px solid $color-gallery;
      border-radius: 10px;
    }

    .link {
      width: 100%;
      color: $color-black;
      border: 1px solid $color-black;
      background-color: $color-white;
      text-align: center;
      padding: 10px 0;
      margin-top: 15px;
      border-radius: 10px;
    }

  }
}