.item {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .info {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cost {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      margin-left: auto;
      margin-right: 20px;
    }
  }
}