@import 'src/sass/variables';

.follow {
  display: flex;
  flex-direction: column;

  &.mobile {
    & > span {
      font-weight: 500;
    }

    & > a {
      font-weight: 500;
    }
  }

  & > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }

  & > a {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }

  .container {
    display: flex;
    margin-top: 10px;

    :not(:first-child) {
      margin-left: 10px;
    }

    &.mobile {
      margin-top: 10px;

      :not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}