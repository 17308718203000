@import 'src/sass/variables';

.main {
  width: 100%;

  .wrapper {
    display: flex;
    width: 100%;
    margin: 7.8% 0;

    &.mobile {
      margin: 50px 0 100px;
      flex-direction: column;
    }

    .contacts {
      display: flex;
      min-width: 600px;
      flex-direction: column;
      align-items: flex-start;

      &.mobile {
        min-width: unset;
      }

      .info {
        margin-top: 70px;
        display: flex;
        flex-direction: column;

        .link {
          color: $color-black;
          align-items: center;
          font-size: 16px;
        }

        .subText {
          margin-left: 25px;
        }

        .textWithIcon {
          width: 100%;
          display: inline-flex;
          grid-gap: 10px;
          align-items: center;
          margin: 10px 0;
        }

        & span, & div {
          line-height: 140%;
          font-weight: 400;
          font-size: 16px;
          color: $color-mine-shaft;

          & + span {
            margin-top: 20px;
          }
        }

        &.mobile {
          margin-top: 20px !important;

          .link {
            font-size: 14px;
          }

          & span, & div {
            font-size: 14px !important;
            white-space: unset !important;

            & + span {
              margin-top: 10px !important;
            }
          }
        }
      }

      .title {
        font-size: 26px;
        font-weight: 700;

        &.mobile {
          font-size: 20px;
        }
      }
    }

    .map {
      margin-left: 8%;
      max-width: 100%;
      width: 100%;
      border-radius: 10px;
      border: 1px solid $color-white;

      &.mobile {
        margin-top: 30px;
        margin-left: unset;
      }
    }
  }
}
