@import 'src/sass/variables';

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $color-gallery;
  border-radius: 10px;

  & + .item {
    margin-top: 20px;
  }

  &.applied {
    border-color: $color-green;
  }

  .coupon {
    padding: 15px;

    .logoPriceRemove {
      display: flex;
      align-items: flex-start;

      .logoWithPrice {
        flex: 1 1 auto;
        display: flex;

        .logo {
          width: 37%;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .cost {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          padding-top: 10px;

          .currentPrice {
            font-size: 16px;
            font-weight: 600;
            line-height: 140%;
          }

          .oldPrice {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            text-decoration-line: line-through;
            color: $color-gray;
          }
        }
      }

      .remove {
        flex: 0 0 30px;
        padding: 5px;
        margin-top: 10px;
      }
    }

    .couponTitle {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      margin-top: 10px;
    }

    .info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;

      .date {
        display: flex;
        flex-direction: column;

        .subtitle {
          font-size: 12px;
          font-weight: 500;
          line-height: 140%;
          color: $color-gray;
        }

        .deadline {
          font-size: 14px;
          font-weight: 500;
          color: $color-black;
          line-height: 140%;
        }
      }
    }

  }

  .options {
    border-top: 1px solid $color-gallery;
    padding: 15px;

    .title {
      font-size: 12px;
      font-weight: 500;
      color: $color-gray;
    }

    .optionsList {
      margin-top: 5px;
    }
  }
}