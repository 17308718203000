@import "../../../../sass/variables";

.container {
  display: flex !important;
  column-gap: 10px !important;
}

.disabled {
  background-color: transparent !important;

  &:focus {
    background-color: transparent  !important;
    color: $color-mine-shaft !important;
  }

  &:active {
    background-color: transparent !important;
    color: $color-mine-shaft !important;
  }

}

.active {
  background-color: $color-candlelight !important;
  color: $color-mine-shaft !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  transition: background-color 0.2s ease-in, color 0.2s ease-in !important;

  &:hover {
    background-color: $color-candlelight !important;
  }

  &:active {
    background-color: $color-candlelight !important;
  }
}
