@import './src/sass/variables';

.btnModal {
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
}

.btn {
  color: $color-mine-shaft !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  transition: background-color 0.2s ease-in;
}

.btn.send:hover {
  background-color: $color-candlelight-dark !important;
}

.btn.cancel {
  border: none !important;

  &:hover {
    color: $color-mine-shaft !important;
  }
}

.btn.disabled {
  background-color: $color-gallery !important;
}

.input, .text {
  margin-bottom: 20px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
