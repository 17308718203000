@import "../../../sass/variables";
.body {
  width: 100%;
  background-color: $color-alabaster;
  padding: 86px 0;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.title {
  font-family: $project-font-family, sans-serif;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: $title-font-size;
  font-weight: 700;
}

.btnActions {
  position: fixed;
  top: 40px;
  right: 50px;
}