@import './src/sass/variables';
.banners-table,
.tableBanners {
  height: 100% !important;
}

.tableBanners {

  .ant-table-header {

    table {
      table-layout: auto !important;
    }
  }
}
