@import "../../../sass/variables";
.containerTable {
  height: 100%;

  .ant-table-wrapper {
    height: 100%;
  }

  .ant-tabs.ant-tabs-top {
    height: 100%;
  }

  .ant-tabs-content-holder {
    height: 100%;
  }
  .ant-tabs-content-top {
    height: 100%;
  }

}

