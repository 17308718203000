@import "src/sass/variables";

.ant-modal {
  width: 440px !important;

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-close-x {
      svg {
        transform: translateY(4px);
      }
    }

    .ant-modal-header {
      border-radius: 4px 4px 0 0;
      border: none;
      padding: 20px;

      .ant-modal-title {
        color: $color-mine-shaft;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .ant-modal-body {
      padding: 0 20px;
    }

    .ant-modal-footer {
      border: none;
      padding: 0 20px 20px;

      .ant-btn {
        font-size: 14px;
        height: auto;
        color: $color-mine-shaft;
        padding: 10px 20px;
      }
    }
  }
}

.img-crop-modal {

  .ant-modal-footer {
    margin-top: 20px;
  }
}
