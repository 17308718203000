@import '../../../../sass/variables';

.modalPromoCode {
  padding: 20px 0;

  .ant-input:hover {
    border-color: $color-candlelight !important;
    border-right-width: 1px !important;
  }

  .ant-form-item-control {
    position: relative !important;
  }

  .ant-modal-content {
    padding-bottom: 20px;
  }
}