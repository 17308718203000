@import 'src/sass/variables';

.containerArea {
  width: 400px !important;
  height: 400px !important;
  margin-bottom: 10px;

  .uploader {
    height: 400px !important;
  }

  .ant-upload.ant-upload-select-picture-card {
    height: 100% !important;
  }
}

.admin-profile-photo-buttons {
  display: flex;
  justify-content: flex-end;

  & > button {
    border-radius: 4px;
    cursor: pointer;
    color: $color-black;
    padding: 10px 20px;
    transition: background-color .2s ease-in, border-color .2s ease-in, color .2s ease-in;

    &:hover {
      color: $color-gray;
    }
  }

  &_save {
    margin-left: 10px;
    background-color: $color-candlelight !important;

    &:hover {
      color: $color-black !important;
      background-color: $color-candlelight-dark !important;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background-color: $color-gallery !important;
      color: $color-gray'' !important;

      &:hover {
        color: unset;
      }
    }
  }
}