@import '../../../sass/variables';

.cart-button {
  position: relative;
  padding: 10px;
  width: 200px;
  height: 42px;
  border-radius: 10px;
  background-color: $color-white;
  border: 1px solid $color-gallery;
  outline: none;
  cursor: pointer;
  color: $color-black;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
  overflow: hidden;
}

.cart-button:hover {
  background-color: $color-candlelight;
  border-color: $color-candlelight;
}

.cart-button .fa-shopping-cart {
  position: absolute;
  //z-index: ;
  width: 36px;
  height: 32px;
  top: 50%;
  left: -10%;
  font-size: 2em;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1300px) {
    left: -15%;
  }
}

.cart-button .fa-box {
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  top: -25%;
  left: 52%;
  font-size: 1.2em;
  transform: translate(-50%, -50%);
}

.cart-button span {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  color: $color-black;
  transform: translate(-50%, -50%);
}

.cart-button span.add-to-cart {
  white-space: nowrap;
  margin-left: unset !important;
  opacity: 1;
}

.cart-button span.added {
  margin-left: unset !important;
  opacity: 0;
}

.cart-button.clicked .fa-shopping-cart {
  animation: cart 1.5s ease-in-out forwards;
}

.cart-button.clicked .fa-box {
  animation: box 1.5s ease-in-out forwards;
}

.cart-button.clicked span.add-to-cart {
  animation: txt1 1.5s ease-in-out forwards;
}

.cart-button.clicked span.added {
  animation: txt2 1.5s ease-in-out forwards;
}

@keyframes cart {
  0% {
    left: -20%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 120%;
  }
}

@keyframes box {
  0%, 40% {
    top: -25%;
  }
  60% {
    top: 38%;
    left: 52%;
  }
  100% {
    top: 38%;
    left: 122%;
  }
}

@keyframes txt1 {
  0% {
    opacity: 1;
  }
  20%, 100% {
    opacity: 0;
  }
}

@keyframes txt2 {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cart-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;

  &:hover {
    background-color: unset;
    border-color: unset;
  }

  & > span {
    pointer-events: none;
  }
}
