@import './src/sass/variables';

.formItem {
  margin-bottom: 20px !important;
  color: $color-mine-shaft !important;
  font-family: $project-font-family, sans-serif !important;
  position: relative !important;

  label {
    font-size: $label-font-size;
    color: $color-gray;
  }
}

.containerSelect {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.formItem.queue {
  flex: 0 0 100px;
}

.btnModal {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.btn {
  color: $color-mine-shaft !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  transition: background-color 0.2s ease-in;
  border: none !important;
  outline: none !important;
  border-radius: 4px !important;
}

.btn.send:hover {
  background-color: $color-candlelight-dark !important;
}

.cancel {
  border: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.error {
  position: absolute;
  bottom: -20px;
  left: 3px;
  color: $color-guardsman-red;
  font-size: $error-font-size;
}
