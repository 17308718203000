@import 'src/sass/variables';

.textBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  padding: 30px;

  &.mobile {
    border: unset;
    padding: unset;
    border-radius: unset;
    margin-top: 50px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
  }

  .text {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
}