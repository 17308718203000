.oneBanner {
  width: 84.4%;
  max-width: 1600px;
  height: 100%;
  margin: 20px auto 0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &.mobile {
    max-height: 185px !important;
    width: calc(100% - 30px) !important;
    object-fit: cover;

    @media screen and (min-width: 460px) {
      height: calc(100vh - 110px) !important;
      max-height: unset !important;
    }
  }

  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 6;
    object-fit: cover;
  }
}
