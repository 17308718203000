@import 'src/sass/variables';

.customer {
  width: 35%;
  margin-left: 30px;

  &.mobile {
    width: 100%;
    margin-left: unset;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
      width: 100%;
      height: 42px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;

      & span {
        margin-left: 10px;
      }
    }

    .buy {
      background-color: $color-candlelight;
      transition: background 0.2s ease-in;
      margin-right: 4%;

      &:hover {
        background-color: $color-candlelight-dark;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
        &:hover {
          background-color: $color-candlelight;
        }
      }
    }
  }
}