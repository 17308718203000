@import 'src/sass/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $color-black;

    &.mobile {
      font-weight: 500;
    }
  }

  & > div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    :not(:first-child) {
      margin-top: 12px;
    }
  }

  &.mobile {

    & > div {
      margin-top: 10px;
    }
  }
}