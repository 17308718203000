@import '_variables.scss';

.ant-table {
  line-height: 1.4 !important;
}

.ant-table-container {


  tr *:is(td, th):first-child {
    padding-left: 20px !important;
  }
}

th {
  background: none !important;
  color: $color-silver-chalice !important;
}

th::before {
  display: none;
}

td, th {
  border: none !important;
}


tr {
  margin-bottom: 10px;
}

tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ant-table-column-title {
  flex: none;
}

.ant-table-filter-column {
  justify-content: normal;
}

.ant-table-row {
  margin-bottom: 0;
}


.ant-table-tbody {

  tr {

    .ant-table-cell {
      font-size: $table-cell-font-size;
      background: $color-white;
      padding-right: 16px !important;
    }
  }
}

.ant-table-cell {

  .ant-table-filter-column {
    display: inline-flex !important;

    .ant-table-filter-trigger:hover {
      background: none;
    }
  }
}

.ant-table-wrapper {
 // margin-top: 10px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  overflow-wrap: unset;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: $color-white !important;
}


.ant-table-row .ant-table-cell-with-append {
  padding-left: 48px !important;
}

.ant-table-expanded-row-level-1 .ant-table-cell {
  padding-left: 23px !important;
}

.ant-table.ant-table-empty {
  height: 100% !important;

  table {
    height: 100% !important;
  }

  .ant-table-container {
    height: 100% !important;
  }

  .ant-table-content {
    height: 100% !important;
  }

  .ant-empty-normal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
  }
}

.all-admin-table,
.adminTable {
  height: 100% !important;
}

.adminTable  {
  margin-top: 10px;

  .ant-table-header {

    table {
      table-layout: auto !important;
      border-collapse: separate !important;
      border-spacing: 0 !important;
      background-color: $color-white-dark;
      min-height: 24px;
    }
  }

  .ant-table-body {

    table {
      border-collapse: separate !important;
      border-spacing: 0 10px !important;
      background-color: $color-white-dark;
    }
  }

  .ant-table-tbody {

    tr:first-child {
      padding-left: 20px;
    }
    tr {

      .ant-table-cell {
        font-size: $table-cell-font-size;
        background: $color-white;
        padding: 0;
        padding-right: 16px !important;
      }
    }
  }

  .ant-table-thead {
    background: $color-white-dark;

    tr {
      .ant-table-cell {
        font-size: $table-header-font-size;
        padding: 0;
      }
    }
  }

  .anticon {
    color: $color-mine-shaft;
  }

  .ant-table-pagination {
    margin-bottom: 10px !important;
  }
}

.adminTable.narrow {

  tr {

    .ant-table-cell {
      padding-right: 10px !important;
    }
  }
}

.ant-table-pagination-center {
  margin-top: 10px !important;
}

.ant-table-empty {

  .ant-table-body {
    height: 100%;
    overflow: hidden !important;
  }

  .ant-table-expanded-row-fixed {
    height: 100%;
  }
}


