@import "../../../../../sass/variables";

#form-merchant .ant-select-selector {
  height: 40px;
}
#form-merchant {

  .ant-checkbox-checked .ant-checkbox-inner::after {
    content: '';
    position: absolute;
    display: table;
    border: 2px solid $color-white;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
}

#form-merchant .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}

#form-merchant .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $color-candlelight;
}

.ant-form-item-control-input-content .react-tel-input {
  font-family: $project-font-family, sans-serif !important;
}

.ant-form-item-control-input-content .react-tel-input:hover .form-control,
.ant-form-item-control-input-content .react-tel-input:hover .flag-dropdown {
  border: 1px solid $color-candlelight;
}

.ant-form-item-control-input-content .selected-flag {
  background-color: $color-white !important;
}

.formItemCheckboxMerchant {

  .ant-form-item-row {
    flex-direction: row;
    align-items: center;
  }

  .ant-form-item-control {
    width: auto !important;
  }
}
