.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 30px 15px 0;

  @media screen and (min-width: 460px) {
    padding: 30px 0 0;
    align-items: center;
    width: 100%;
  }

  .wrapper {
    width: 100%;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
    }

    @media screen and (min-width: 460px) {
      width: 65%;
    }
  }
}