@import 'src/sass/variables';

.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;
    margin-bottom: 50px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 40px;
  }

  & > button {
    cursor: pointer;
    background-color: $color-candlelight;
    border-radius: 10px;
    transition: background-color 0.2s ease-in;
    width: 189px;
    height: 42px;
    margin-top: 20px;
    font-weight: 600;

    &:hover {
      background-color: $color-candlelight-dark;
    }
  }
}