@import "src/sass/variables";

body {
  font-family: $project-font-family, sans-serif !important;
  line-height: 1.4 !important;
}
#root {
  height: 100%;
}


* {
  scrollbar-width: thin;
  scrollbar-color: $color-candlelight transparent;
  -webkit-overflow-scrolling: touch;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color-candlelight;
  border-radius: 52px;
  transition: background-color .2s ease-in;

  li {
    padding-left: 20px !important;
    width: 60px !important;
  }
}

html {
  --antd-wave-shadow-color: $color-candlelight!important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.errorCouponText {
  position: absolute;
  bottom: -15.5px;
  left: 1px;
  font-size: $error-font-little-size;
  color: $color-guardsman-red;
}

.errorCouponTextPrevImage {
  position: absolute;
  bottom: -10.5px;
  left: 1px;
  font-size: $error-font-little-size;
  color: $color-guardsman-red;
}



.errorCouponText.errorTimePciker {
  position: relative !important;
  top: 0;
  left: 0;
}

.errorGeneralText {
  font-size: $error-font-size;
  color: $color-guardsman-red;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 4px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #333!important;
  border-color: transparent!important;
  color: $color-white!important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-input-suffix {
  color: $color-gray;
}


.ant-picker .ant-picker-input input::placeholder {
  color: $color-gray;
}


.tabsAntForAdminTables {

  .ant-tabs-nav:before {
    border-bottom: none !important;
  }

  .ant-pagination-next, .ant-pagination-prev {
    border: none !important;
  }

  .ant-tabs-tab-btn {
    color: $color-silver-chalice !important;
  }

  .ant-tabs-tab-active {

    .ant-tabs-tab-btn {
      color: $color-mine-shaft !important;
    }
  }

  .ant-tabs-ink-bar {
    background: $color-candlelight !important;
  }
}

.dropdownBtnFilter {

  .ant-dropdown-trigger {
    background-color: transparent;

    svg {
      transform: translateY(-2px);
      color: $color-gray;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.dropdownWrapperHeader {

  .ant-dropdown-button {

    .ant-btn-default {
      display: none;
    }

    .ant-dropdown-trigger {
      border: none;
      display: block;
    }
  }
}

.closeDropdownOutSide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.clearDropdownFilter {
  display: flex;
  justify-content: flex-end;
  color: $color-gray;
  font-size: $modal-small-fontSize;
  cursor: pointer;
  padding: 0 15px;
  font-weight: 500;
}

.activeItemSelected {
  background-color: $color-candlelight;
  font-weight: 500;
}

.menuFilterRadio {
  color: $color-mine-shaft !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;

  .ant-radio-inner {
    border-color: $color-mine-shaft !important;
  }

  &__text {
    position: relative;
    top: 4px !important;
  }
}


.inputComponent-label-class {
  flex-direction: column !important;

  .ant-form-item-label {
    width: 100% !important;
    margin-bottom: 5px;

    label {
      display: block;
      height: auto;
    }
  }
}

.ant-tooltip.tooltipAdminTableColumns.maxWidthTooltip {
  max-width: 100%;
}

.ant-tooltip.tooltipAdminTableColumns.maxWidthReportTooltip {
  max-width: 30%;
}

.ant-tooltip.tooltipAdminTableColumns {
  max-width: 300px;

  .ant-tooltip-content {

    .ant-tooltip-arrow {

      .ant-tooltip-arrow-content {
        display: none !important;
      }
    }

    .ant-tooltip-inner {
      min-width: 30px;
      width: auto;
      min-height: 32px;
      padding: 6px 8px;
      color: $color-white !important;
      text-align: left;
      text-decoration: none;
      word-wrap: break-word;
      background-color: $color-black !important;
      border-radius: 2px;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    }
  }
}

.fieldWithIdAdminTable {
  display: flex;
  align-items: center;

  .ant-typography {
    flex: 0 0 60px;
  }
}

.copyIdAdminTablesIcon {
  margin-left: 5px;
  cursor: pointer;
  color: $color-gray;
  position: relative;
  top:2px;
}

.ant-message-notice.messageSuccessTableAdmin {

  .ant-message-notice-content {
    padding: 20px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba($color-mine-shaft, 0.05);

    .ant-message-custom-content {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: $usual-font-size;
      color: $color-mine-shaft;
      line-height: 20px;

      svg {
        color: $color-green;
      }
    }
  }
}
