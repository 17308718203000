@import "../../../../sass/variables";
.radious {
  margin-bottom: 10px;
}
.days {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  width: 380px !important;
}
