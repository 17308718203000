@import 'src/sass/variables';
.modalDeleteBanner {

  .ant-modal-content {
    padding-bottom: 20px;
  }

  .ant-input:hover {
    border-color: $color-candlelight;
    border-right-width: 1px !important;
  }

}