@import "../../../../../../../../sass/variables";
.addressSearchContainer {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
}

.addressSearchContainer.error {
  margin-bottom: 15px;
}

.errorInputAutocomplete {
  border-color: $color-guardsman-red !important;
}

.addressSearchContainer .ant-select-auto-complete {
  height: 100%;
}

.addressSearchContainer .ant-select-auto-complete .ant-select-selector {
  height: 100% !important;
}

.addressSearchContainer .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $color-candlelight !important;
  border-right-width: 1px !important;
}
