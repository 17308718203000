@import 'src/sass/variables';

.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .back {
    display: flex;
    padding-bottom: 20px;
    align-items: center;

    & > span {
      font-weight: 500;
      font-size: 14px;
      margin-left: 15px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

  }

  .search {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    color: $color-gray;

    & > svg {
      position: absolute;
      top: 22px;
      right: 12px;
    }

    & > input {
      height: 40px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid $color-gallery;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $color-black;
  }
}

.refund {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .formItem {
    margin-bottom: 10px !important;

    & > div {
      padding-bottom: unset;
    }

    & textarea {
      margin: 5px 0 0;
      border-radius: 10px;
    }

    label {
      margin-top: 10px;
      height: unset;

      &::before {
        content: none !important;
      }
    }
  }

  & > button {
    padding: 10px 0;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    background-color: $color-candlelight;
    border: 1px solid $color-candlelight;
    border-radius: 10px;
    margin-top: 10px;
    transition: 0.2s background-color ease-in, 0.2s border-color ease-in;

    &:active {
      background-color: $color-white;
      border-color: $color-black;
    }
  }

  & span {
    font-weight: 400;
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

:global {
  .ant-input {
    font-size: 16px !important;
  }
}