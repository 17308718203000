@import 'src/sass/variables';

.main {
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    margin: 30px 7.8% 100px;
    width: 84.4%;
    max-width: 1600px;

    &.mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: unset;

      .details.mobile {
        padding: 20px 15px;
      }
    }

    .header {
      margin-top: 50px;
      font-weight: 700;
      font-size: 26px;
      line-height: 140%;

      &.mobile {
        margin-top: unset;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      margin-top: 20px;
      width: 100%;

      .details {
        display: flex;
        flex-direction: column;
        width: 65%;

        .social {
          display: flex;
          margin-top: 23px;
          align-items: center;

          .icon {
            cursor: pointer;
            margin-right: 21px;
          }
        }
      }
    }
  }
}