@import 'src/sass/variables';

.doublePicker.ant-picker-dropdown {
  width: unset !important;

  .ant-picker-date-panel {
    width: 210px !important;

    .ant-picker-content {
      width: unset !important;

      & td {
        width: 30px !important;
      }
    }
  }

  .ant-picker-panel-container {
    padding: 30px;
    box-shadow: 0 4px 16px $color-shadow-dark !important;
    border-radius: 4px;
  }

  .ant-picker-panels > :last-child {
    position: relative;
    margin-left: 40px;

    &::before {
      position: absolute;
      content: '';
      width: 1px;
      background-color: $color-gallery;
      left: -20px;
      height: 100%;
    }
  }

  .ant-picker-header {

    border-bottom: unset;

    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
      visibility: unset !important;
      color: $color-black;
    }

    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
      display: none;
    }
  }

  .ant-picker-cell-range-end,
  .ant-picker-cell-range-start {
    background-color: $color-candlelight;
    border-radius: 4px;

    &::before {
      content: unset;
    }
  }

  .ant-picker-cell-in-range {
    background-color: rgba($color-candlelight, 0.1);
  }
}

.doublePickerInput {
  visibility: hidden;
  padding: 0;
  width: 0 !important;
  border: unset !important;
  height: 0;

  & input {
    min-width: unset;
  }

  .ant-picker-range-separator {
    display: none;
  }
}

.DoublePickerCloser {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rangePickerContainer {
  height: 0;
}
