@import 'src/sass/variables';

.summary {
  display: flex;
  flex-direction: column;
  max-width: 513px;
  min-width: 260px;
  width: 100%;
  margin: 10px 0 0 30px;

  & > span {
    color: $color-silver-chalice !important;
  }

  .summaryContent {
    margin-top: 9px;
    padding: 30px;
    border: 1px solid $color-gallery;
    border-radius: 10px;

    &.mobile {
      margin-top: 20px;
      padding: unset;
      border: unset;
      border-radius: unset;
    }

    .form {
      display: flex;

      .input {
        position: inherit;
        height: 42px;
        border-radius: 10px;
        width: 100%;
        padding: 10px 15px;
        font-size: 16px;

        &::placeholder {
          color: $color-silver-chalice !important;;

        }
      }

      .inputError {
        border: 1px solid $color-guardsman-red;
      }

      .apply {
        line-height: 140%;
        margin-left: 10px;
        font-weight: 600;
        height: 42px;
        cursor: pointer;
        padding: 10px 20px;
        border: 1.5px solid $color-black;
        border-radius: 10px;
        transition: border-color 0.2s ease-in, background-color 0.2s ease-in;

        &:hover {
          background-color: $color-candlelight-dark;
          border-color: $color-candlelight-dark;
        }

        &.mobile {

          &:hover {
            border-color: $color-black;
            background-color: $color-white;
          }

          &:active {
            background-color: $color-candlelight-dark;
            border-color: $color-candlelight-dark;
          }
        }
      }
    }

    .prices {
      & > div {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

    .submit {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        justify-content: space-between;

        & + div {
          margin-top: 20px;

          & span {
            color: $color-green;
          }
        }
      }

      & > button {
        width: 100%;
        margin-top: 30px;
        line-height: 140%;
        cursor: pointer;
        padding: 10px 0;
        border-radius: 10px;
        background-color: $color-candlelight;
        transition: background-color 0.2s ease-in;
        font-weight: 600;

        &:hover {
          background-color: $color-candlelight-dark;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;

          &:hover {
            background-color: $color-candlelight;
          }
        }
      }
    }

    .divider {
      width: 100%;
      margin: 30px 0;
    }
  }

  & > span {
    margin-left: 20px;
    font-size: 12px;
    color: $color-gray;
  }

  &.mobile {
    margin: unset;
    max-width: unset;
    min-width: unset;
    position: fixed;
    bottom: 65px;
    left: 0;
    z-index: 3;
    height: 65px;
    justify-content: center;

    .summaryTitle {
      display: flex;
      position: relative;
      align-items: center;
      text-align: center;
      justify-content: center;

      & > span {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }

      .closeIcon {
        color: $color-gray;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    .mobileTotal {
      background-color: $color-white;
      padding: 12px 15px;
      display: flex;
      justify-content: space-between;
      height: 100%;
      border: solid $color-gallery;
      border-width: 1px 0;

      & > button {
        width: 160px;
        background-color: $color-candlelight;
        border-radius: 10px;
        transition: 0.2s background-color ease-in;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;

        &:active {
          background-color: $color-candlelight-dark;
        }

        &:disabled {
          opacity: 0.3;
          background-color: $color-candlelight;
        }
      }

      .mobileCost {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .subtitle {
          font-size: 12px;
          color: $color-gray;
          font-weight: 500;
          line-height: 140%;
        }

        .costWithIcon {
          display: flex;

          .cost {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
          }

          .icon {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.originalPrice {

  & span {
    color: $color-gray;
  }

  & strong {
    color: $color-gray;
  }
}

.promoDiscount {
  & span {
    color: $color-green;
  }
}