@import 'src/sass/variables';

.main {
  height: 100%;
  width: 100%;

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .link {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      color: $color-black;
      background-color: $color-candlelight;
      padding: 10px 0;
      border-radius: 10px;
      font-weight: 500;
    }
  }
}