@import 'src/sass/variables';

.receipt {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .static {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    & > span {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;

      & + span {
        margin-top: 10px;
      }
    }

    .bold {
      font-weight: 500;
    }
  }

  .payment {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      & + div {
        margin-top: 10px;
      }
    }
  }

  .vouchers {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      width: 100%;
      padding: 10px;
      border: 1px solid $color-gallery;
      border-radius: 10px;

      & + .item {
        margin-top: 10px;
      }

      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & + div {
          margin-top: 10px;
        }
      }
    }
  }

  .total {
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  color: $color-gray;
}

.value {
  color: $color-black;
  font-size: 14px;
  font-weight: 500;
}

.divider {
  width: 100%;
  margin: 20px 0;
}