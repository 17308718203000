.vouchers-merchant {

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    height: 100%;
  }
}
