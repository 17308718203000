@import 'src/sass/variables';

.main {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;

  &.mobile {
    margin: unset;
    padding: 30px 15px 100px;
    display: flex;

    &.center {
      align-items: center;
    }
  }

  .container {
    width: 84.4%;
    max-width: 1600px;
    margin: 0 7.8%;

    &.mobile {
      max-width: unset;
      margin: unset;
      width: 100%;
    }

    .cartHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
      }

      .continue {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        transition: color 0.2s ease-in;

        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }

        &:hover {
          color: $color-gray;
        }
      }
    }

    .content {
      width: 100%;
      margin-top: 10px;
      display: flex;

      &.mobile {
        flex-direction: column;
        margin-top: unset;
      }

      .coupons {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}