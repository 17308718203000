@import "../../../sass/variables";

.uploadImageContainer {
  position: relative;
  height: 230px !important;
  margin-bottom: 7px;

  .ant-upload.ant-upload-select-picture-card {
    height: 230px !important;
  }
}

.uploadImageContainer.active {
  height: 230px !important;

  .uploader {
    height: 230px !important;

   .ant-upload {
     height: 230px !important;
   }
  }

}
.deleteImage {

  .iconDelete {
    font-size: 16px;
    font-weight: 500;
    padding: 3px;
    border-radius: 2px;
    background-color: rgba($color-white, 0.75);
  }
}

.uploader {
  position: relative !important;
  height: 150px !important;

  .ant-upload {
    width: 100% !important;
  }
}

.prevImage {
  position: relative;
  height: 100%;
  width: 400px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  text-align: center;
}

.ant-upload-list-picture-card-container {
  position: relative;
}

.uploadImage {

  .anticon-delete {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px !important;
    height: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.upload_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.upload_title {
  font-size: $upload-image-font-size;
  font-weight: 500;

  span {
    display: inline-block;
    position: relative;
    margin-left: 3px;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $color-mine-shaft;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.upload_subtitle {
  color:$color-silver-chalice;
}

.deleteImage {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99!important;
  align-items: center;
  background-color:$color-white;
  border-radius: 4px;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
}

.uploader.error .ant-upload-select {
  border: 1px dashed $color-guardsman-red;
}
