@import 'src/sass/variables';

.main {
  position: relative;
  width: 100%;
  max-width: 1057px;

  .poster {
    border-radius: 10px;
    width: 100%;
    height: 593px;
  }

  .controls {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    .gallery {
      position: absolute;
      display: flex;
      align-items: center;
      height: 127px;
    }
  }
}
