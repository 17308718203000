@import "../../../../../../sass/variables";
.btnContainer {
  display: flex;
  justify-content: flex-end;

  border: none !important;
  box-shadow: none !important;
  outline: none !important;

  .ant-btn {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: $color-gray !important;
  }
}