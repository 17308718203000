@import 'src/sass/variables';

.text-areas {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__item {
    position: relative;

    p {
      font-size: $label-font-size;
      color: $color-dark-gray;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
}
