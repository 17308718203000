@import 'src/sass/variables';

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & label::before {
    display: none !important;
  }

  .profile {
    display: flex;
    flex-direction: column;

    .back {
      display: flex;
      padding-bottom: 20px;
      align-items: center;

      & > span {
        font-weight: 500;
        font-size: 14px;
        margin-left: 15px;
      }
    }

    .title {
      font-weight: 700;
      line-height: 140%;
      font-size: 26px;

      &.mobile {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .item {
      border-radius: 10px;
      padding: 10px 15px;
      transition: none;
    }

    .disabled {
      border: 1px solid transparent;
      padding-left: 0;
      background-color: $color-white;
      color: $color-black;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button {
      width: 100%;
      background-color: $color-candlelight;
      border-radius: 10px;
      text-align: center;
      padding: 10px 0;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid $color-candlelight;
      opacity: 1;
      transition: 0.2s opacity ease-in, 0.2s background-color ease-in;

      & + .button {
        margin-left: 4%;
      }

      &:disabled {
        background-color: $color-white;
        opacity: 0.5;
      }

      &.cancel {
        border: 1px solid $color-black;
        background-color: $color-white;
      }
    }
  }
}

.phoneContainer {
  border-radius: 10px !important;
  height: 44px;
  width: 100%;

  & input {
    border-radius: 10px !important;
    color: $color-black;
    height: 100% !important;
    width: 100% !important;
    cursor: text !important;
    background: transparent !important;
  }

  .flagDropdown {
    border-radius: 10px 0 0 10px !important;

    & > div {
      border-radius: 10px 0 0 10px !important;
    }
  }

  &.active {
    & div,
    input {
      border: none;
      padding-left: 10px;


      &:hover {
        border: unset !important;
      }
    }
  }
}