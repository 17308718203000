@import 'src/sass/variables';

.item {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 407px;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: 0 4px 16px rgba($color-shadow-dark, 0.05)
  }

  .hot {
    cursor: pointer;
    position: absolute;
    border-radius: 10px;
    //display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba($color-white, 0.75);
    top: 9px;
    right: 10px;
    width: 32px;
    height: 32px;
    z-index: 1;
  }

  .image {
    width: 100%;
    max-height: 211px;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }

  .description {
    font-size: 16px;
    color: $color-silver-chalice;
    height: 44px;
    font-weight: 400;
    line-height: 140%;
    margin: 15px 15px 0;
    overflow: hidden;
  }

  .discountContainer {
    margin: 10px 15px 0;
    display: flex;
    flex: 1;

    & span {
      font-size: 20px;
      font-weight: 700;
      color: $color-black;
      line-height: 140%;
    }

    .discount {
      margin-left: 10px;
      color: $color-guardsman-red;
    }
  }

  .oldPrice {
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    text-decoration-line: line-through;
    color: $color-silver-chalice;
    margin: 5px 15px 0;
  }

  .button {
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
    width: calc(100% - 30px);
    height: 42px;
    margin: 15px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}
