@import 'src/sass/variables';

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;

  h1,
  h2 {
    font-size: 16px;
    color: $color-mine-shaft;
    line-height: 140%;
    font-weight: 400;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 30px;
  }

  button {
    cursor: pointer;
    width: 100%;
    background-color: $color-candlelight;
    border: 1px solid $color-candlelight;
    border-radius: 10px;
    transition: .2s background-color ease-in, .2s border-color ease-in;
    padding: 10px 0;
    font-weight: 600;

    &:hover {
      background-color: $color-candlelight-dark;
      border-color: $color-candlelight-dark;
    }

    & + button {
      margin-left: 10px;
    }
  }

  .subtitle {
    width: 100%;
    text-align: center;
    border: 1px solid $color-gallery;
    border-radius: 10px;
    padding: 20px 0;
    margin-bottom: 20px;

    & span {
      font-size: 16px;
      line-height: 140%;
      color: $color-mine-shaft;
      font-weight: 400;
    }
  }

  .buttons {
    width: 100%;
    display: flex;

    & button:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    .cancel {
      background-color: $color-white;
      border-color: $color-black;

      &:hover {
        background-color: $color-candlelight-dark;
        border-color: $color-candlelight-dark;
      }
    }
  }
}