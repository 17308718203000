@import 'src/sass/variables';

.signUp {
  width: 460px;
  margin: 0 auto;
  padding: 30px;
  font-family: $project-font-family, sans-serif;
  border: 1px solid $color-gallery;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  font-size: $button-font-bigger-size;
  font-weight: 600;
  line-height: 140%;
  padding: 10px 20px;
  transition: background 0.3s ease-in;
  border-radius: 10px;
  background-color: rgb($color-gallery, 0);
  margin: 0.2em 7px 0;
  white-space: nowrap;

  &:hover {
    background-color: rgba($color-gallery, 0.5);
  }
}

.singInContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: $text-font-size;

  .title {
    font-weight: 700;
    font-size: $text-title-font-size;
    line-height: 36px;
  }

  .cross {
    cursor: pointer;
  }
}

.form {
  margin-top: 30px !important;

  & label {
    color: $color-silver-chalice !important;
    font-size: $label-font-size;

    &:before {
      display: none !important;
    }
  }

  .item {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    & button {
      border-radius: 10px !important;
    }

    .input {
      height: 42px;
      width: 400px;
      border-radius: 10px;
      font-size: $input-big-font-size;
      line-height: 140% !important;
      font-weight: 400;


      & div {
        font-weight: 500;
        font-size: $label-font-size;
        line-height: 140%;
      }
    }

    .error {
      position: absolute;
      display: inline-block;
      bottom: -5px;
      left: 0;
    }
  }

  .item > div {
    padding: 0;
  }
}

.submitButton {
  width: 100%;
  border-radius: 10px;
  height: 42px !important;
  color: $color-black !important;
  padding: 10px 20px;
  font-size: $button-font-bigger-size !important;
  font-weight: 600 !important;
  line-height: 140%;
  background-color: $color-candlelight;
  transition: border-color 0.2s ease-in, background-color 0.2s ease-in;
  opacity: 1;

  &:hover {
    border-color: $color-candlelight-dark;
    background-color: $color-candlelight-dark;
  }
}

.util {
  display: inline-block;
  width: 100%;
  font-size: $text-font-size;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  user-select: none;
  color: $color-silver-chalice;
}

.memberBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;

  .label {
    font-weight: 400;
    font-size: $label-big-font-size;
    line-height: 140%;
    color: $color-silver-chalice;
  }

  .signUp {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    font-weight: 600;
    font-size: $text-font-size;
    line-height: 140%;

    &:after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      transition: background 0.1s ease-in;
      background-color: rgba($color-black, 0);
    }

    &:hover::after {
      background-color: rgba($color-black, 1);
    }
  }
}

.passwordContainer {
  position: relative;

  .forgot {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    font-size: $text-little-font-size;
    font-weight: 500 !important;
    right: 0;
    color: rgba($color-black, 1);
    top: -22px;
    transition: 0.2s ease;
    z-index: 1;

    &:hover {
      color: rgba($color-black, 0.7);
    }
  }
}

input {
  -webkit-box-shadow: inset 0 0 0 50px $color-white;
  -webkit-text-fill-color: $color-black;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.checkbox {
  font-size: $checkbox-font-size;
  font-weight: 400;
  line-height: 140%;
  margin-left: 2px;
  padding-top: 0.2em;
}
