@import 'src/sass/variables';

.wrapper {
  width: 100%;

  &.mobile {
    height: 230px;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  background-color: rgba($color-white, 0.5);
  cursor: pointer;
  transition: background 0.2s;
  z-index: 1;

  &[aria-disabled="true"] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba($color-white, 0.3);
  }

  &:active {
    transition: none;
    background-color: rgba($color-white, 1);
  }
}

.next {
  right: 20px;
  z-index: 4;
}

.prev {
  left: 20px;
}

.videoPreview {
  position: relative;

  & > svg {
    position: absolute;
    transform: translate(-50%, 50%);
    left: 50%;
  }
}