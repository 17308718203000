@import 'src/sass/variables';

.link {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: $color-black;
  line-height: 140%;

  &:hover {
    text-decoration: underline;
  }
}