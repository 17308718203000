@import 'src/sass/variables';

.main {
  padding-left: 31px;
  min-height: 42px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $color-category;
  }

  &.selected {
    background-color: $color-white;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      width: 15px;
      height: 15px;
    }

    .title {
      line-height: 140%;
      margin-left: 11px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}