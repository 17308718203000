@import '../../../../../sass/variables';

.payment-header {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-family: Inter, sans-serif;
  font-size: $text-modal-content-fontSize;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-gallery;

  &__title{
    margin: 0;
    font-size: $text-modal-title-fontSize;
    color:$color-mine-shaft;
    font-weight: 700;
  }

  &__body {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    row-gap: 5px;
  }
  &__item.title {
    font-weight: 600;
  }
  &__item.left {
    text-align: right;
  }
}
