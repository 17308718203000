@import 'src/sass/variables';

#formEmploy {

  .ant-form-item-explain-error {
    font-size: $error-font-size;
    color: $color-guardsman-red;
  }

  .ant-form-item-explain-connected {
    position: absolute !important;
    left: 2px !important;
    bottom: -25px !important;
  }


  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    background-color: $color-white;
    border-color: $color-guardsman-red;
    box-shadow: none !important;
  }

  .radioRoleEmploy {
    color:$color-mine-shaft !important;
    font-size: $radio-font-size !important;
  }

  .formItemRadio {
    display: flex;
    flex-direction: row !important;
    column-gap: 50px !important;
  }

  .ant-input {
    border: 1px solid $color-gallery;
  }
}
