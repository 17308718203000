@import 'src/sass/variables';

.main {
  width: 100%;
  background-color: $color-white;
  margin: 0 auto;
  color: $color-black;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.mobile {
    width: 100%;
  }

  .container {
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    width: 84.4%;

    &.mobile {
      width: 100%;
      max-width: calc(100% - 15px * 2);
      margin: 0 auto;

      @media screen and (min-width: 460px) {
        max-width: 65%;
      }
    }

    .title {
      color: $color-mine-shaft;
      font-weight: 700;
      font-size: 50px;
      line-height: 140%;
      margin-top: 100px;

      &.mobile {
        font-size: 20px;
        margin-top: 50px;
        color: $color-mine-shaft;
      }
    }

    .catalogContent {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin: 50px 0 100px;

      &.mobile {
        flex-direction: column;
        margin: 10px 0 50px;
      }
    }
  }
}

.filterContainer {
  max-width: 392px;
  width: 100%;

  &.mobile {
    width: 100%;
    min-width: 100%;
  }
}

.wideSlider {
  max-width: 1600px;
  width: 84.4%;
  margin: 20px auto 0;
}
