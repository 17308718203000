@import "src/sass/variables";

.main {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 80px;
  border: solid $color-gallery;
  border-width: 0 0 1px 0;
  background-color: $color-white;
  z-index: 2;

  &.mobile {
    bottom: 0;
    border-width: 1px 0 0;
    height: 65px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0px,0px,0px);
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 7.8%;
    width: 84.4%;
    max-width: 1600px;

    &.mobile {
      margin: unset;
      padding: 10px 15px 0;
    }

    .categories {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px 20px;
      margin: 0 25px;
      transition: 0.2s ease;
      border-radius: 10px;
      background-color: rgba($color-gallery, 0);

      @media screen and (max-width: 1330px) {
        margin: 0 5px;
      }

      &:hover {
        background-color: rgba($color-gallery, 0.5);
      }

      & span {
        line-height: 140%;
        font-weight: 600;
        font-size: 16px;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }
}

.barContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  & a {
    color: $color-gray;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (min-width: 460px) {
      width: 65%;
      justify-content: space-between;
      padding: 0;
    }

    .cartLink {
      position: relative;

      .couponsCount {
        position: absolute;
        font-size: 12px;
        line-height: 140%;
        font-weight: 500;
        padding: 0 5px;
        right: 15px;
        bottom: 25px;
        background-color: $color-candlelight;
        border-radius: 10px;
      }
    }

    .navLink {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $color-gray;

      & > span {
        font-size: 12px;
        line-height: 140%;
        font-weight: 500;
        margin-top: 3px;
      }

      &.active {
        color: $color-black;
      }
    }
  }
}