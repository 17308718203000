@import 'src/sass/variables';

.main {
  width: 100%;
  height: 118px;
  display: flex;

  &.mobile {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid $color-gallery;
    border-radius: 10px;
    flex-direction: column;
    height: unset;

    .mobileContainer {
      display: flex;

      .white.mobile {
        width: 50%;
        padding: unset;
        border: none;

        .cost {
          .currentPrice {
            flex: 1;
            font-size: 20px;
            font-weight: 700;
          }

          .oldPrice {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .green.mobile {
        background-color: transparent;
        color: $color-green;
        width: 50%;
        border: none;
        justify-content: unset;
        align-items: unset;
        text-align: right;

        .discount {
          font-size: 20px;
          font-weight: 700;
          padding: unset;
        }

        .date {
          font-size: 14px;
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
  }

  .white {
    position: relative;
    width: 63%;
    height: 100%;
    border: solid $color-gallery;
    border-width: 1px 0 1px 1px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    padding: 6%;

    &.whiteNoDiscount {
      width: 100%;
      border-radius: 10px;
      border-width: 1px;
    }

    @media screen and (max-width: 1610px) {
      padding: 3%;
    }

    .cost {
      display: flex;
      flex-direction: column;

      .currentPrice {
        font-size: 26px;
        font-weight: 700;
        line-height: 140%;

        @media screen and (max-width: 1740px) {
          font-size: 24px;
          font-weight: 600;
        }

        @media screen and (max-width: 1300px) {
          font-size: 22px;
        }
      }

      .oldPrice {
        color: $color-silver-chalice;
        text-decoration-line: line-through;
        font-size: 16px;
        line-height: 140%;
        font-weight: 400;

        @media screen and (max-width: 1740px) {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .green {
    height: 100%;
    width: 37%;
    background-color: $color-green;
    border-radius: 0 10px 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $color-white;

    .discount {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;

      @media screen and (max-width: 1740px) {
        font-size: 18px;
        font-weight: 600;
      }

      @media screen and (max-width: 1610px) {
        padding: 3%;
      }
    }

    .date {
      font-size: 16px;
      line-height: 140%;
      font-weight: 400;
    }
  }
}
