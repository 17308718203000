@import "src/sass/variables";

.data-picker-container {
  width: 100% !important;
}

.ant-picker-input > input::placeholder {
  color:$color-guardsman-red;
}

.data-picker-promo-code-wrapper {
  width: 400px;

  .ant-picker-panel,
  .ant-picker-date-panel {
    width: 100%;
  }

  .ant-picker-header-view {
    font-weight: 600;
    font-size: $data-picker-font-size;
  }

  .ant-picker-header-next-btn,
  .ant-picker-header-prev-btn{
    color: $color-mine-shaft;
  }

  .ant-picker-header {
    padding: 10px 70px;
  }

  .ant-picker-body {
    padding: 10px 30px;
  }
  .ant-picker-body .ant-picker-content{
    width: 100%;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-footer {
    display: none;
  }
}
