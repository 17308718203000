@import'src/sass/variables';

.profile-merchant-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;

  &__btn-cancel {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
      color: $color-mine-shaft;
    }
  }
}
