@import 'src/sass/variables';

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .logout {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .qrcode {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    & > button,
    a {
      text-align: center;
      color: $color-black;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      padding: 10px 0;
      border-radius: 10px;
      transition: 0.2s background-color ease-in, 0.2s border-color ease-in;
    }

    .scan {
      background-color: $color-candlelight;
      border: 1px solid $color-candlelight;

      &:active {
        background-color: $color-white;
        border-color: $color-black;
      }
    }

    .vouchers {
      background-color: $color-white;
      border: 1px solid $color-black;
      margin-top: 10px;

      &:active {
        background-color: $color-candlelight;
        border-color: $color-candlelight;
      }
    }
  }
}