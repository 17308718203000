@import "src/sass/variables";

.radioGroupCommon {

  label + label {
    margin-top: 12px;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1.3px solid $color-black;
  }

  .ant-checkbox-wrapper.ant-checkbox-group-item {

    :last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      color: $color-black;
    }
  }
}
