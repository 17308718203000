@import 'src/sass/variables';

.quantity {
  position: absolute;
  bottom: 18px;
  right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1300px) {
    right: 2%;
  }

  &.mobile {
    margin-top: 10px;
    position: static;
    bottom: unset;
    right: unset;
  }

  .header {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: $color-silver-chalice;

    &.mobile {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .quantityContainer {
    display: flex;
    align-items: center;

    &.mobile {
      margin-top: 10px;
    }

    .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 5px;
      border: 1px solid $color-white;
      transition: border-color 0.2s ease-in;

      &.mobile {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border-color: $color-gallery;
      }

      &:hover {
        border-color: $color-gallery;
      }

      &:active {
        transition: none;
        border-color: $color-white;
        background-color: $color-gallery;;
      }
    }

    .quantityCount {
      user-select: none;
      margin: 0 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;

      &.mobile {
        margin: unset;
        padding: 0 20px;
        font-size: 14px;
      }
    }
  }
}