@import 'src/sass/variables';

.logoContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 15px;

  .logo img {
    width: 60px;
    height: 60px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
      line-height: 140%;
      font-weight: 700;
      font-size: 20px;
      margin-left: 10px;
      color: $color-mine-shaft;
    }

    .subtitle {
      color: $color-silver-chalice;
      line-height: 14px;
      font-size: 10px;
      font-weight: 400;
      margin-left: 10px;

      @media screen and (min-width: 1921px) {
        justify-content: center;
      }
    }
  }
}
